import { useEffect, useState } from 'react';

const PREFIX = 'bulksmasher-';

export default function useSessionStorage(key, initialValue) {
	const prefixedKey = PREFIX + key;
	const [value, setValue] = useState(() => {
		const jsonValue = sessionStorage.getItem(prefixedKey);
		if (
			jsonValue !== null &&
			jsonValue !== undefined &&
			jsonValue !== 'null' &&
			jsonValue !== 'undefined'
		)
			return JSON.parse(jsonValue);
		if (typeof initialValue === 'function') {
			return initialValue();
		} else {
			return initialValue;
		}
	});

	useEffect(() => {
		sessionStorage.setItem(prefixedKey, JSON.stringify(value));
	}, [prefixedKey, value]);

	return [value, setValue];
}
