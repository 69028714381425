import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, FormGroup } from '@material-ui/core';
import axios from 'axios';
import { useAuthDispatch, logoutUser } from '../../context/auth';
import LoadingMessage from '../../components/LoadingMessage';
import useOperatorPermissions from '../../hooks/useOperatorPermissions';
import useIFrame from '../../hooks/useIFrame';

export default function CategorySelector({
	category,
	setCategory,
	operationJSONArray,
	setOperationJSONArray,
	children,
}) {
	const dispatch = useAuthDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const operatorPermissions = useOperatorPermissions();
	const isIFramed = useIFrame();

	const getJSON = async (category) => {
		setIsLoading(true);
		try {
			const res = await axios.get(`${category}/json`).catch((e) => {
				setIsLoading(false);
				if (e.response.status === 401) {
					logoutUser(dispatch);
				}
				return null;
			});
			setIsLoading(false);
			return res?.data.data;
		} catch (e) {
			return null;
		}
	};

	useEffect(() => {
		if (!category) {
			setOperationJSONArray([]);
			return;
		}
		if (
			operationJSONArray.length > 0 &&
			category === operationJSONArray[0].category
		)
			return;
		setOperationJSONArray([]);
		getJSON(category)
			.then((jsonArray) => {
				if (isIFramed) {
					//Determine User's Permission Level
					const newJSONArray = getPermittedOperators(
						jsonArray,
						operatorPermissions
					);
					setOperationJSONArray(newJSONArray);
				} else {
					setOperationJSONArray(jsonArray);
				}
			})
			.catch((e) => console.log(e));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category]);

	const handleChange = (e) => {
		setCategory(e.target.value);
	};

	return (
		<>
			<FormGroup className="form-group">
				<label
					style={{
						fontSize: '1rem',
						marginBottom: '0.75rem',
					}}
				>
					Choose the type of Uberflip resource to work with:
				</label>
				<FormControl variant="outlined" fullWidth>
					<InputLabel htmlFor="outlined-category-native-simple">
						Resource
					</InputLabel>
					<Select
						value={category}
						onChange={handleChange}
						label="Resource"
						inputProps={{
							name: 'category',
							id: 'outlined-category-native-simple',
						}}
					>
						{children}
					</Select>
				</FormControl>
			</FormGroup>
			{isLoading && <LoadingMessage>Loading Actions...</LoadingMessage>}
		</>
	);
}

function getPermittedOperators(operatorArray, bulksmasherPermissions) {
	const permittedOperators = operatorArray.reduce((acc, operatorJSON) => {
		const operator = operatorJSON.operator;

		bulksmasherPermissions.forEach((permission) => {
			if (operator.toLowerCase().includes(permission))
				acc.push(operatorJSON);
		});
		return acc;
	}, []);

	return permittedOperators;
}
