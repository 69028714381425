let Operators = {};

//Items
Operators['Create Items'] = require('./Universal/TsvOperator').default;
Operators['Create Item-Level Custom Code Block'] =
	require('./Universal/TsvOperator').default;
Operators['Export Items'] = require('./Universal/ReadOperator').default;
Operators['Export Item-Level Custom Code Blocks'] =
	require('./Universal/ReadOperator').default;
Operators['Update Items'] = require('./Universal/TsvOperator').default;
Operators['Update Item-Level Custom Code Blocks'] =
	require('./Universal/TsvOperator').default;
Operators['Delete Items'] = require('./Universal/TsvOperator').default;
Operators['Delete Item-Level Custom Code Blocks'] =
	require('./Universal/TsvOperator').default;
Operators['Delete Items Older Than Date'] =
	require('./Items/DeletePastItems').default;
Operators['Hide/Show Items Older Than Date'] =
	require('./Items/HideShowPastItems').default;
Operators['Remove Secondary URLs from Items'] =
	require('./Items/DeleteSecondaryUrls').default;

//CTAs
Operators['Update CTAs'] = require('./Universal/TsvOperator').default;
Operators['Export CTAs'] = require('./Universal/ReadOperator').default;
Operators['Delete CTAs'] = require('./Universal/TsvOperator').default;

//CTA Placement
Operators['Export CTA Placements'] =
	require('./Universal/ReadOperator').default;
Operators['Create CTA Placements'] = require('./Universal/TsvOperator').default;
Operators['Update CTA Placements'] = require('./Universal/TsvOperator').default;
Operators['Delete CTA Placements'] = require('./Universal/TsvOperator').default;


//Tags
Operators['Create Tags'] = require('./Universal/TsvOperator').default;
Operators['Export Tags'] = require('./Universal/ReadOperator').default;
Operators['Update Tags'] = require('./Universal/TsvOperator').default;
Operators['Delete Tags'] = require('./Universal/TsvOperator').default;
Operators['Delete All Tags'] = require('./Tags/DeleteAllTags').default;
Operators['Delete Unused Tags'] = require('./Tags/DeleteUnusedTags').default;
Operators['Remove Category from Tag Names'] =
	require('./Tags/RemoveCategory').default;

//Streams
Operators['Create Streams'] = require('./Universal/TsvOperator').default;
Operators['Export Streams'] = require('./Universal/ReadOperator').default;
Operators['Update Streams'] = require('./Universal/TsvOperator').default;
Operators['Update Stream Options'] = require('./Universal/TsvOperator').default;
Operators['Delete Streams'] = require('./Universal/TsvOperator').default;
Operators['Remove Items from Marketing/Sales Streams'] =
	require('./Universal/TsvOperator').default;
Operators['Remove Hidden Items from Marketing/Sales Streams'] =
	require('./Universal/TsvOperator').default;
Operators['Remove Secondary URLs from Streams'] =
	require('./Streams/DeleteSecondaryUrls').default;

//Filters
Operators['Create Smart Filters'] = require('./Universal/TsvOperator').default;
Operators['Update Smart Filters'] = require('./Universal/TsvOperator').default;
Operators['Export Smart Filters'] = require('./Universal/ReadOperator').default;
Operators['Delete Smart Filters'] = require('./Universal/TsvOperator').default;

//Redirects
Operators['Create Redirect Rules'] = require('./Universal/TsvOperator').default;
Operators['Export Redirect Rules'] =
	require('./Universal/ReadOperator').default;
Operators['Update Redirect Rules'] = require('./Universal/TsvOperator').default;
Operators['Delete Redirect Rules'] = require('./Universal/TsvOperator').default;

//Users
Operators['Create Users'] = require('./Universal/TsvOperator').default;
Operators['Export Users'] = require('./Universal/ReadOperator').default;
Operators['Update Users'] = require('./Universal/TsvOperator').default;
Operators['Delete Users'] = require('./Universal/TsvOperator').default;

//PDFS
Operators['Create Flipbooks/PDFs'] = require('./Universal/TsvOperator').default;
Operators['Export Flipbooks/PDFs'] =
	require('./Universal/ReadOperator').default;

export default Operators;
