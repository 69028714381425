import React, { useState } from 'react';
import {
	makeStyles,
	FormControl,
	FormHelperText,
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	Typography,
	Button,
	CircularProgress,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Link,
	Tooltip,
} from '@material-ui/core';
import {
	Visibility,
	VisibilityOff,
	ExpandMore,
	Lock,
} from '@material-ui/icons/';
import {
	useAuthDispatch,
	useAuthState,
	validateLegacyCreds,
} from '../../context/auth';

export default function LegacyLogin() {
	const styles = useStyles();
	const dispatch = useAuthDispatch();
	const {
		validatedLegacyCredentials,
		validatePrimaryUser,
		errorMessage,
		hubs,
	} = useAuthState();
	const [legacyKey, setLegacyKey] = useState('');
	const [legacySignature, setLegacySignature] = useState('');
	const [validatingCreds, setValidatingCreds] = useState(false);
	const [showLegacyKey, setShowLegacyKey] = useState(false);
	const [showLegacySignature, setShowLegacySignature] = useState(false);
	const [showLegacyKeyEye, setShowLegacyKeyEye] = useState(false);
	const [showLegacySignatureEye, setShowLegacySignatureEye] = useState(false);
	const [expandedAccordion, setExpandedAccordion] = useState(
		!validatedLegacyCredentials
	);

	const handleValidateLegacyCreds = async (e) => {
		setValidatingCreds(true);
		e.preventDefault();

		try {
			const validationResult = await validateLegacyCreds(
				dispatch,
				legacyKey,
				legacySignature,
				hubs,
				setExpandedAccordion
			);

			if (validationResult.validatedLegacyCredentials) {
				setExpandedAccordion(false);
				setLegacyKey('');
				setLegacySignature('');
			}
		} finally {
			setValidatingCreds(false);
		}
	};

	const handleTogglePassword = (password, stateFunction) => {
		stateFunction(!password);
	};

	const AccordionTitle = () => (
		<div className="align-center">
			{validatedLegacyCredentials && (
				<Tooltip title="Legacy credentials are securely saved" arrow>
					<Lock style={{ color: '#0e8a44', paddingRight: '5px' }} />
				</Tooltip>
			)}

			{validatePrimaryUser ? (
				<p>Save Legacy Credentials</p>
			) : (
				<p style={{ fontWeight: 'bolder' }}>Bulk actions unavailable</p>
			)}
		</div>
	);

	const disabled =
		legacyKey === '' || legacySignature === '' || validatingCreds;

	return (
		<Accordion
			variant="outlined"
			className={styles.accordion}
			style={{
				marginTop: '2rem',
				borderRadius: '4px',
				border: '1px solid rgba(0, 0, 0, 0.2)',
			}}
			sx={{
				'&:before': {
					display: 'none',
				},
			}}
			expanded={expandedAccordion}
			onChange={() => {
				setExpandedAccordion(!expandedAccordion);
			}}
		>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<AccordionTitle />
			</AccordionSummary>
			<AccordionDetails>
				<form className={styles.form}>
					{validatePrimaryUser ? (
						<Typography>
							Bulk actions for Flipbooks/PDFs use the Uberflip
							Legacy API. The Legacy API is separate from the
							Uberflip REST API used with other types of
							resources, and requires separate login credentials.{' '}
							<Link
								href="https://app.uberflip.com/account/api"
								target="_blank"
								rel="noopener noreferrer"
							>
								Click here to get your Legacy API credentials
							</Link>
							.
						</Typography>
					) : (
						<Typography>
							These bulk actions are unavailable because you are
							logged in with a user account that is not the
							account’s{' '}
							<Link
								href="https://help.uberflip.com/hc/en-us/articles/360018726352"
								target="_blank"
								rel="noopener noreferrer"
							>
								Primary User.
							</Link>
						</Typography>
					)}

					<Typography
						style={{
							paddingTop: '20px',
							paddingBottom: '20px',
						}}
					>
						{validatePrimaryUser
							? validatedLegacyCredentials
								? 'You currently have credentials securely saved. To save new credentials, please enter your Legacy API credentials'
								: 'To proceed, enter your Legacy API credentials:'
							: 'Bulk actions for the Flipbooks/PDFs resource use the Uberflip Legacy API, which can only be accessed by the Primary User. To use these bulk actions, please log in as the Primary User.'}
					</Typography>

					{validatePrimaryUser ? (
						<div className={styles.formControls}>
							<FormControl>
								<InputLabel htmlFor="input-field-key">
									API Key
								</InputLabel>
								<Input
									id="input-field-key"
									type={showLegacyKey ? 'text' : 'password'}
									value={legacyKey}
									error={errorMessage}
									disabled={validatingCreds}
									onChange={(e) => {
										setLegacyKey(e.target.value.trim());
									}}
									onMouseEnter={() =>
										setShowLegacyKeyEye(true)
									}
									onMouseLeave={() =>
										setShowLegacyKeyEye(false)
									}
									autoFocus
									required
									endAdornment={
										showLegacyKeyEye && (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														handleTogglePassword(
															showLegacyKey,
															setShowLegacyKey
														);
													}}
												>
													{showLegacyKey ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										)
									}
								/>
							</FormControl>

							<FormControl>
								<InputLabel htmlFor="input-field-signature">
									Signature
								</InputLabel>
								<Input
									id="input-field-signature"
									type={
										showLegacySignature
											? 'text'
											: 'password'
									}
									value={legacySignature}
									error={errorMessage}
									disabled={validatingCreds}
									onChange={(e) =>
										setLegacySignature(
											e.target.value.trim()
										)
									}
									onMouseEnter={() =>
										setShowLegacySignatureEye(true)
									}
									onMouseLeave={() =>
										setShowLegacySignatureEye(false)
									}
									required
									endAdornment={
										showLegacySignatureEye && (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														handleTogglePassword(
															showLegacySignature,
															setShowLegacySignature
														);
													}}
												>
													{showLegacySignature ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										)
									}
								/>
								<FormHelperText
									id="input-field-email"
									className={styles.error}
								>
									{errorMessage &&
										'Please enter valid Legacy API credentials.'}
								</FormHelperText>
							</FormControl>

							<Button
								variant="contained"
								disabled={disabled}
								onClick={handleValidateLegacyCreds}
								style={{
									margin: '0',
									marginTop: '20px',
									height: '42px',
								}}
								fullWidth
							>
								{validatingCreds ? (
									<>
										<CircularProgress
											color="primary"
											size={30}
											className={styles.progress}
											style={{
												marginRight: '10px',
											}}
										/>{' '}
										Saving...
									</>
								) : (
									'VALIDATE AND SAVE CREDENTIALS'
								)}
							</Button>
						</div>
					) : (
						''
					)}
				</form>
			</AccordionDetails>
		</Accordion>
	);
}

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& .MuiFormControl-root': {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			width: '100%',
		},
		'& .MuiButtonBase-root': {
			margin: theme.spacing(2),
		},
	},
	formControls: {},
	error: {
		color: '#f44336',
	},
	credentials: {
		marginTop: '20px',
		display: 'flex',
		justifyContent: 'center',
	},
}));
