import { useEffect } from 'react';
import { useAuthState } from '../context/auth';

function pendoInitialize(userId, accountId) {
	window.pendo.initialize({
		visitor: {
			id: userId,
		},
		account: {
			id: accountId,
		},
	});
}

export default function PendoInitialize() {
	const { accountId, userId } = useAuthState();

	useEffect(() => {
		if (accountId && userId) pendoInitialize(userId, accountId);
	}, [accountId, userId]);

	return null;
}
