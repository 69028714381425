import React, { useEffect, useState } from 'react';
import {
	FormControl,
	InputLabel,
	Select,
	FormGroup,
	MenuItem,
	ListSubheader,
	Tooltip,
} from '@material-ui/core';
import {
	validateUser,
	logoutUser,
	useAuthDispatch,
	useAuthState,
} from '../../context/auth';
import LegacyLogin from '../Login/LegacyLogin';

export default function OperationSelector({
	category,
	operators,
	operator,
	setOperator,
}) {
	const dispatch = useAuthDispatch();
	const { validatedLegacyCredentials, validatePrimaryUser } = useAuthState();
	const [sortedOperators, setSortedOperators] = useState(null);

	useEffect(() => {
		const sortedOperators = sortOperators(operators);
		setSortedOperators(sortedOperators);
	}, [operators]);

	const handleChange = (e) => {
		if (!e.target.value) setOperator({});
		else setOperator(JSON.parse(e.target.value));
		validateUser(dispatch).catch((e) => {
			logoutUser(dispatch);
		});
	};

	const validatePrimaryUserConditionWithPDF =
		category !== 'PDFs' ? true : validatePrimaryUser;
	const isPDF = category === 'PDFs';
	const authenticatedLegacyCredentials =
		category === 'PDFs' && !validatedLegacyCredentials;

	useEffect(() => {
		if (category === 'PDFs' && !validatedLegacyCredentials) {
			setOperator({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validatedLegacyCredentials]);

	return (
		<>
			{isPDF && <LegacyLogin />}
			{validatePrimaryUserConditionWithPDF ? (
				<FormGroup className="form-group">
					<label
						style={{
							fontSize: '1rem',
							marginTop: '0.75rem',
							marginBottom: '0.75rem',
						}}
					>
						Choose a bulk action to apply to the selected resource:
					</label>
					<FormControl variant="outlined" fullWidth>
						<InputLabel htmlFor="outlined-operation-native-simple">
							Action
						</InputLabel>
						<Tooltip
							arrow="top"
							title={
								authenticatedLegacyCredentials
									? 'Please enter and save your Legacy API credentials'
									: ''
							}
						>
							<Select
								value={
									isEmpty(operator)
										? ''
										: JSON.stringify(operator)
								}
								disabled={authenticatedLegacyCredentials}
								onChange={handleChange}
								label="Action"
								inputProps={{
									name: 'Action',
									id: 'outlined-operation-native-simple',
								}}
							>
								{generateMenuItems(sortedOperators)}
							</Select>
						</Tooltip>
					</FormControl>
				</FormGroup>
			) : (
				''
			)}
		</>
	);
}

function generateMenuItems(sortedOperators) {
	const options = [];
	if (sortedOperators) {
		sortedOperators.general?.length > 0 &&
			options.push(
				<div key={'General'}>
					<ListSubheader>General</ListSubheader>
				</div>
			);

		sortedOperators.general.forEach((operator) => {
			options.push(
				<MenuItem key={operator.name} value={JSON.stringify(operator)}>
					{operator.name}
				</MenuItem>
			);
		});
		for (const grouping in sortedOperators.grouped) {
			if (Object.hasOwnProperty.call(sortedOperators.grouped, grouping)) {
				const operators = sortedOperators.grouped[grouping];
				options.push(
					<div key={grouping}>
						<ListSubheader>{grouping}</ListSubheader>
					</div>
				);
				operators.forEach((operator) => {
					options.push(
						<MenuItem
							key={operator.name}
							value={JSON.stringify(operator)}
						>
							{operator.name}
						</MenuItem>
					);
				});
			}
		}
	}

	return options;
}

function sortOperators(operators) {
	const sortedOperators = operators.reduce(
		(acc, operator) => {
			const grouping = operator.grouping;
			if (grouping) {
				if (!acc.grouped.hasOwnProperty(grouping)) {
					acc.grouped[grouping] = [];
				}
				acc.grouped[grouping].push(operator);
			} else {
				acc.general.push(operator);
			}

			return acc;
		},
		{ grouped: {}, general: [] }
	);
	return sortedOperators;
}

function isEmpty(obj) {
	return Object.keys(obj).length === 0;
}
