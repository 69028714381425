import React, { useState } from 'react';
import { FormGroup, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { CloudUpload, CloudDownload } from '@material-ui/icons';
import TabPanel from './TabPanel';

const getStyle = (isActive) => {
	return isActive ? { color: '#0e8a44' } : { color: '#000000' };
};

export default function TabControl({ children }) {
	const [currentTabIndex, setCurrentTabIndex] = useState(false);
	const shortenLabel = useMediaQuery('(max-width:770px)');

	const handleTabChange = (event, currentTabIndex) => {
		setCurrentTabIndex(currentTabIndex);
	};

	return (
		<FormGroup className="form-group" style={{ marginTop: '2rem' }}>
			<div style={{ maxWidth: '100%' }}>
				<Tabs
					value={currentTabIndex}
					onChange={handleTabChange}
					variant="fullWidth"
					TabIndicatorProps={{
						style: {
							background: '#0e8a44',
						},
					}}
				>
					<Tab
						label={
							shortenLabel ? 'DOWNLOAD' : 'DOWNLOAD JOB TEMPLATES'
						}
						icon={<CloudDownload />}
						style={getStyle(currentTabIndex === 0)}
					/>
					<Tab
						label={shortenLabel ? 'UPLOAD' : 'UPLOAD JOB TEMPLATES'}
						icon={<CloudUpload />}
						style={getStyle(currentTabIndex === 1)}
					/>
				</Tabs>

				{children.map((childComponent, index) => {
					return (
						<TabPanel
							key={index}
							index={index}
							value={currentTabIndex}
						>
							<br></br>
							{childComponent}
						</TabPanel>
					);
				})}
			</div>
		</FormGroup>
	);
}
