import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { logoutUser, useAuthDispatch } from '../../../../context/auth';
import JobConfirmModal from '../../../../components/Modals/JobConfirmModal';
import JobAlerts from '../../../../components/Alerts/JobAlerts';
import SocketDataExport from '../../../../components/SocketDataExport/SocketDataExport';
import TableAccordion from '../../../../components/TableAccordion';

const DeleteAllTags = ({ operatorData }) => {
	const dispatch = useAuthDispatch();
	const [fileData, setFileData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [affectedItems, setAffectedItems] = useState(null);
	const [displayModal, setDisplayModal] = useState(false);
	const [jobStatus, setJobStatus] = useState({
		loading: false,
		state: null,
		jobId: null,
		message: null,
	});

	useEffect(() => {
		if (fileData.length === 0) return;
		const formattedTags = fileData.reduce((acc, val) => {
			acc.push({
				tag_id: val.id,
				tag_name: val.name,
				tag_items: val.items_count,
			});
			return acc;
		}, []);

		setFilteredData(formattedTags);
		setAffectedItems(formattedTags.length);
	}, [fileData, operatorData]);

	useEffect(() => {
		if (jobStatus.state === 'success') {
			setFileData([]);
		}
		console.log(jobStatus);
	}, [jobStatus]);

	const handleSetFileData = (data) => {
		setFileData(data);
	};

	const handleDisplayModal = (boolean) => {
		setDisplayModal(boolean);
	};

	const handleSetJobStatus = (data) => {
		setJobStatus(data);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setJobStatus({
			loading: true,
			state: null,
			jobId: null,
			message: null,
		});

		const email = sessionStorage.getItem('email');

		axios
			.post(
				process.env.REACT_APP_USE_LOCAL_WORKER === 'false'
					? operatorData.endpoint
					: 'jobs/create',
				{
					email: email,
					fileData: filteredData,
					operatorData: operatorData,
				},
				{ withCredentials: true }
			)
			.then((res) => {
				setJobStatus({
					loading: false,
					state: 'success',
					jobId: res?.data?.data,
					message: res?.data?.message,
				});
			})
			.catch((e) => {
				setJobStatus({
					loading: false,
					state: 'error',
					jobId: null,
					code: e?.response?.status,
					message: e?.response?.data?.message,
				});
				if (e.response.status === 401) {
					logoutUser(dispatch);
				}
			});
	};

	return (
		<>
			{jobStatus.state === 'success' ? (
				<></>
			) : fileData.length === 0 ? (
				<SocketDataExport
					operatorData={operatorData}
					handleSetFileData={handleSetFileData}
					jobStatus={jobStatus}
					handleSetJobStatus={handleSetJobStatus}
					buttonText={`Find ${operatorData.category}`}
					initializingText={`Searching ${operatorData.category}`}
					downloadingText={`Extracting ${operatorData.category}`}
				></SocketDataExport>
			) : (
				<>
					{filteredData?.length === 0 && (
						<>
							<Typography>
								Found <b>0</b> tags in your account.
							</Typography>
							<br />
						</>
					)}
					{filteredData?.length > 0 && (
						<div>
							<TableAccordion
								label={`Found ${filteredData.length} Tags (click to expand)`}
								data={filteredData}
							/>
							<Button
								variant="contained"
								onClick={() => handleDisplayModal(true)}
								disabled={
									jobStatus.loading || !(affectedItems > 0)
								}
								style={{
									marginTop: '2rem',
									marginBottom: '2rem',
									height: '42px',
								}}
								fullWidth
							>
								{jobStatus.loading ? (
									<>
										<CircularProgress
											size={30}
											style={{
												marginRight: '10px',
											}}
										/>{' '}
										SUBMITTING JOB...
									</>
								) : (
									'SUBMIT JOB'
								)}
							</Button>
						</div>
					)}

					<JobConfirmModal
						operatorData={operatorData}
						displayModal={displayModal}
						fileLength={filteredData.length}
						handleDisplayModal={handleDisplayModal}
						handleJobSubmit={handleSubmit}
					/>
				</>
			)}

			{jobStatus.state !== null && (
				<JobAlerts
					jobStatus={jobStatus}
					operatorData={operatorData}
					fileLength={affectedItems}
				/>
			)}
		</>
	);
};

export default DeleteAllTags;
