import React, { useState, useRef } from 'react';

const FileDragAndDrop = ({ readXLSXFile }) => {
	const [dragActive, setDragActive] = useState(false);
	const inputRef = useRef(null);

	function handleDrag(e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	}

	function handleDrop(e) {
		setDragActive(false);
		const file = e.dataTransfer?.files[0];
		e.preventDefault();
		e.stopPropagation();
		if (!file) return;
		readXLSXFile(file);
	}

	return (
		<form
			style={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
			onDragEnter={handleDrag}
			onSubmit={(e) => e.preventDefault()}
		>
			<input
				ref={inputRef}
				style={{ display: 'none' }}
				accept=".json"
				type="file"
				onChange={(e) => {
					const file = e.target.files?.[0];
					if (!file) return;
					// setUploadedFile(file);
				}}
			/>

			<div
				id="drag_and_drop_container"
				className="align_column_items"
				onDragEnter={handleDrag}
				onDragLeave={handleDrag}
				onDragOver={handleDrag}
				onDrop={handleDrop}
				style={{
					backgroundColor: dragActive ? '#d0d8dc' : '#E8F0F5',
					border: '1px dashed #a1aeb6',
					borderRadius: '8px',
					padding: '20px',
					width: '100%',
					textAlign: 'center',
				}}
			>
				<div
					style={{ display: 'flex', flexDirection: 'column' }}
					className="uploaded-file-name"
				>
					<h5
						style={{
							fontSize: '0.875rem',
							margin: '5px',
							textTransform: 'uppercase',
						}}
					>
						DRAG AND DROP TO UPLOAD FILE
					</h5>
				</div>
			</div>
		</form>
	);
};

export default FileDragAndDrop;
