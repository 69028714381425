import React, { useState } from 'react';
import { FormControl, TextField, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useAuthState } from '../context/auth';

export default function HubPicker({ setHubIds }) {
	const { hubs } = useAuthState();
	const [values, setValues] = useState([]);

	const onChange = (_, value) => {
		setValues(value);
		setHubIds(value?.map((hub) => hub.id) || []);
	};

	const label = 'Select Hub(s)';

	return (
		<FormControl size="small" variant="outlined">
			<Autocomplete
				id="hub-picker"
				multiple
				style={{ width: '300px' }}
				size="medium"
				options={hubs}
				getOptionLabel={(option) => option.name}
				value={values}
				onChange={onChange}
				limitTags={1}
				disableCloseOnSelect
				renderOption={(option, { selected }) => (
					<>
						<Checkbox color="primary" checked={selected} />
						{option.name}
					</>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						variant="outlined"
						InputProps={{
							...params.InputProps,
						}}
					/>
				)}
			/>
		</FormControl>
	);
}
