import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import prettyBytes from 'pretty-bytes';

const TsvDataTable = ({ tableData, fileName, fileSize }) => {
	const [tableColumns, setTableColumns] = useState([]);

	function dataToString(variable) {
		// ENG-5492 - Showing null in places we are resetting values
		if (variable === '') return 'null';
		if (variable === undefined) return '';
		return variable.toString();
	}

	useEffect(() => {
		if (tableData.length === 0) {
			setTableColumns([]);
			return;
		}

		const columns = Object.keys(tableData[0]).map((header) => {
			return {
				name: header,
				selector: (row) => dataToString(row[header]),
				sortable: true,
				minWidth: '200px',
				maxWidth: '300px',
			};
		});

		setTableColumns(columns);
	}, [tableData]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: '5px 0px 15px 0px',
				}}
			>
				<Typography
					style={{
						fontSize: '1.17em',
						color: 'rgba(0, 0, 0, 0.87)',
						minWidth: '105px',
					}}
				>
					File Preview
				</Typography>
				<Typography
					style={{
						paddingLeft: '10px',
						fontSize: '0.9rem',
						color: 'rgba(0, 0, 0, 0.54)',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{fileName} ({prettyBytes(fileSize)})
				</Typography>
			</div>

			<DataTable
				noHeader={true}
				columns={tableColumns}
				data={tableData}
				dense={true}
				striped={true}
				highlightOnHover={true}
				noDataComponent={''}
				responsive={true}
				pagination={true}
				paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
			/>
		</>
	);
};

export default TsvDataTable;
