import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const filters = ['ALL', 'ERRORS', 'FATAL ERRORS'];

export default function FilterToggleButtons({
	filterMethod,
	handleSetFilterMethod,
}) {
	return (
		<ToggleButtonGroup
			size="small"
			value={filterMethod}
			color="primary"
			exclusive
			onChange={handleSetFilterMethod}
			aria-label="log filter"
		>
			{filters.map((filter) => {
				return (
					<ToggleButton
						value={filter}
						aria-label={filter}
						key={filter}
					>
						{filter}
					</ToggleButton>
				);
			})}
		</ToggleButtonGroup>
	);
}
