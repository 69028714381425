import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';

export default function InfoAlert({ title, children }) {
	return (
		<Alert style={{ marginTop: '20px' }} severity="info" variant="outlined">
			<AlertTitle>{title}</AlertTitle>
			{children}
		</Alert>
	);
}
