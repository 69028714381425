import React from 'react';
import {
	makeStyles,
	Modal,
	Backdrop,
	Fade,
	Select,
	MenuItem,
	Button,
	Divider,
	Typography,
	FormGroup,
	FormControl,
	InputLabel,
} from '@material-ui/core/';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Info } from '@material-ui/icons';

const SheetsConfirmModal = ({
	operatorData,
	sheets,
	selectedSheet,
	setSelectedSheet,
	displayModal,
	handleDisplayModal,
	readFile,
}) => {
	const styles = useStyles();

	const handleClose = () => {
		handleDisplayModal(false);
	};

	return (
		<>
			<Modal
				closeAfterTransition
				className={styles.modal}
				open={displayModal}
				onClose={handleClose}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={displayModal}>
					<div className={styles.header}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Info
								style={{
									margin: '5px 10px 5px 20px',
									color: '#2196f3',
								}}
							/>
							<h3>Select a Sheet to Upload</h3>
						</div>

						<div className={styles.body}>
							<Alert
								severity="info"
								variant="filled"
								icon={false}
								style={{
									color: '#004085',
									backgroundColor: '#cce5ff',
									border: 'solid 1px #b8daff',
								}}
							>
								<AlertTitle>
									<Typography
										style={{ marginTop: '0.35rem' }}
									>
										The Excel file you provided has multiple
										sheets. Please select one sheet from the
										following dropdown to proceed with your{' '}
										{operatorData.name} job.
									</Typography>
								</AlertTitle>
							</Alert>

							<br />

							<FormGroup className="form-group">
								<FormControl
									size="small"
									variant="outlined"
									style={{ width: '300px' }}
								>
									<InputLabel htmlFor="outlined-category-native-simple">
										Select a sheet
									</InputLabel>
									<Select
										label={'Select a sheet'}
										value={selectedSheet}
										className={styles.select}
										inputProps={{
											name: 'status',
											id: 'outlined-category-native-simple',
										}}
										onChange={(e) =>
											setSelectedSheet(e.target.value)
										}
									>
										{Object.keys(sheets).map(
											(sheet, index) => {
												return (
													<MenuItem
														key={index}
														value={sheet}
													>
														{sheet}
													</MenuItem>
												);
											}
										)}
									</Select>
								</FormControl>
							</FormGroup>
						</div>
						<Divider style={{ marginTop: '15px' }} />
						<div className={styles.footer}>
							<Button
								variant="outlined"
								className={styles.cancelButton}
								onClick={handleClose}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								size="medium"
								disabled={selectedSheet === ''}
								onClick={(e) => {
									readFile();
									handleClose();
								}}
							>
								Confirm Sheet
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '15%',
	},
	header: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		wordWrap: 'break-word',
		borderRadius: '5px !important',
		maxWidth: '700px',
		padding: '15px',
		lineHeight: '1.5',
	},
	body: {
		padding: '0px 20px 20px 20px',
		fontSize: '12px',
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '15px 0px 0px 0px',
	},
	cancelButton: {
		textTransform: 'none',
		marginRight: '10px',
	},
	select: {
		minWidth: '200px',
	},
}));

export default SheetsConfirmModal;
