import React from 'react';
import {
	AutoSizer,
	Column,
	Table,
	CellMeasurer,
	CellMeasurerCache,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Button } from '@material-ui/core';

export default function VirtualizedTable({ filteredLogs, columns }) {
	// create new cache on mount
	const cache = new CellMeasurerCache({
		fixedWidth: true,
		defaultHeight: 75,
	});

	function rowStyleFormat(row) {
		let defaultRowStyle = {
			fontSize: '12px',
		};

		// even row numbers
		if ((row.index & 1) === 0) {
			return {
				...defaultRowStyle,
				backgroundColor: '#fafafa',
			};
		}

		return defaultRowStyle;
	}

	const columnCellRenderer = (props) => {
		const { dataKey, parent, rowIndex, columnIndex, style } = props;
		const datum = filteredLogs[rowIndex];

		return (
			<CellMeasurer
				cache={cache}
				columnIndex={columnIndex}
				key={dataKey}
				parent={parent}
				rowIndex={rowIndex}
			>
				<div
					key={`[${rowIndex}][${columnIndex}]`}
					style={{
						...style,
						whiteSpace: 'normal',
						overflow: 'visible',
						padding: '5px 0px 5px 0px',
					}}
				>
					{columnIndex === 0 ? datum[dataKey] + 1 : datum[dataKey]}
				</div>
			</CellMeasurer>
		);
	};

	return (
		<AutoSizer disableHeight>
			{({ width }) => (
				<Table
					width={width}
					height={390}
					headerHeight={30}
					headerStyle={{
						textTransform: 'none',
						padding: '10px 0px 0px 0px !important',
					}}
					deferredMeasurementCache={cache}
					rowHeight={cache.rowHeight}
					rowStyle={rowStyleFormat}
					rowCount={filteredLogs.length}
					rowGetter={({ index }) => filteredLogs[index]}
					noRowsRenderer={() => <Button disabled>Empty Row</Button>}
				>
					{columns.map((header, index) => {
						return (
							<Column
								key={header.name}
								style={
									index === columns.length - 1 && {
										marginRight: '20px',
									}
								}
								headerStyle={
									index === columns.length - 1 && {
										marginRight: '20px',
									}
								}
								width={
									header.name === 'index'
										? 40
										: header.name === 'timestamp'
										? 170
										: header.name === 'level'
										? 60
										: header.name === 'operator'
										? 160
										: header.name === 'message'
										? 300
										: header.name === 'details'
										? 300
										: // legacy logs
										header.name === 'data'
										? width
										: 100
								}
								label={header.friendlyName}
								dataKey={header.name}
								cellRenderer={columnCellRenderer}
							/>
						);
					})}
				</Table>
			)}
		</AutoSizer>
	);
}
