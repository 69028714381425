import React from 'react';
import { FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';

const TemplateGeneratorParameter = ({ param, handleToggleParam }) => {
	return (
		<Tooltip
			title={param.required ? 'Required header' : ''}
			placement="top"
			arrow
		>
			<FormControlLabel
				control={
					<Checkbox
						value={param.name}
						onChange={(e) => {
							handleToggleParam(param.name);
						}}
						color="primary"
						disabled={param.required}
						checked={param.required || param.selected}
					/>
				}
				label={param.name}
			/>
		</Tooltip>
	);
};

export default React.memo(TemplateGeneratorParameter);
