import React from 'react';
import { makeStyles, Link } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useAuthState } from '../../context/auth';

export default function TableAlerts({ status, code }) {
	const styles = useStyles();
	const { accountId } = useAuthState();

	if (status === 'warning') {
		return (
			<Alert
				className={styles.success}
				severity="warning"
				variant="outlined"
			>
				<AlertTitle>No Jobs Found</AlertTitle>
				<div className={styles.body}>
					No jobs have been executed for <b>{accountId}</b>. Please
					visit the{' '}
					<Link href="/" variant="body2">
						home
					</Link>{' '}
					page to run a Bulk Task Smasher job to utilize this page.
				</div>
			</Alert>
		);
	} else if (status === 'error') {
		return (
			<Alert
				style={{ marginTop: '32px' }}
				severity="error"
				variant="outlined"
			>
				<AlertTitle>[{code}] Error</AlertTitle>

				<div>
					An error occurred loading <b>{accountId}</b> jobs. Please
					try again.
				</div>

				<div className={styles.body}>
					If this persists, please contact Uberflip Support.
				</div>
			</Alert>
		);
	} else return null;
}

const useStyles = makeStyles((theme) => ({
	success: {
		marginTop: '32px',
	},
	body: {
		marginTop: '10px',
	},
}));
