import { useAuthState } from '../context/auth';

const baseString = '_BULK_TASK_SMASHER_2_';

export default function useOperatorPermissions() {
	const { userPermissions, userType } = useAuthState();
	if (userType === 'primary') return ['create', 'read', 'update', 'delete'];

	const bulksmasherPermissions = userPermissions.reduce(
		(acc, { code: permission }) => {
			if (!permission) return acc;
			if (permission.includes(baseString + 'CREATE')) {
				acc.push('create');
			} else if (permission.includes(baseString + 'UPDATE')) {
				acc.push('update');
			} else if (permission.includes(baseString + 'DELETE')) {
				acc.push('delete');
			}
			return acc;
		},
		['read'] //all users can use read operators
	);

	return bulksmasherPermissions;
}
