import React, { useState, useEffect } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import QuantityDialog from './QuantityDialog';
import TemplateGeneratorParameter from './TemplateGeneratorParameter';

const DEFAULT_QUANTITY = 5;

const AsterixParameter = ({
	param,
	handleToggleParam,
	handleSetParamQuantity,
}) => {
	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		if (param.quantity === undefined)
			handleSetParamQuantity(param.name, DEFAULT_QUANTITY);
	}, [param.name, param.quantity, handleSetParamQuantity]);

	function handleSetShowDialog(boolean) {
		setShowDialog(boolean);
	}

	function setParamQuantity(quantity) {
		handleSetParamQuantity(param.name, quantity);
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
			}}
		>
			<TemplateGeneratorParameter
				param={param}
				handleToggleParam={handleToggleParam}
			/>
			{param.selected && (
				<>
					<div style={{ color: '#AAA', marginLeft: '-0.75em' }}>
						x{param.quantity}
					</div>
					<ClickAwayListener
						onClickAway={() => {
							if (!showDialog) return;
							handleSetShowDialog(false);
						}}
					>
						<div>
							<IconButton
								size="small"
								aria-label="edit quantity"
								onClick={() => {
									handleSetShowDialog(!showDialog);
								}}
							>
								<Edit
									style={{
										color: '#AAA',
										opacity: showDialog ? 0 : 1,
									}}
								/>
							</IconButton>

							{showDialog && (
								<QuantityDialog
									initialQuantity={param.quantity}
									setParamQuantity={setParamQuantity}
									handleSetShowDialog={handleSetShowDialog}
								/>
							)}
						</div>
					</ClickAwayListener>
				</>
			)}
		</div>
	);
};

export default React.memo(AsterixParameter);
