import React, { useEffect, useState, useCallback } from 'react';
import { Close } from '@material-ui/icons';
import {
	TextField,
	IconButton,
	Tooltip,
	InputAdornment,
} from '@material-ui/core';
import _ from 'lodash';

export default function FilterComponent({
	initialTableData,
	handleSetTableData,
}) {
	const [searchKey, setSearchKey] = useState('');

	const filterTableData = () => {
		const tempFiltered = initialTableData.filter(({ jobId }) =>
			jobId.includes(searchKey)
		);
		handleSetTableData(tempFiltered);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFilterFunction = useCallback(
		_.debounce(filterTableData, 100, [])
	);

	useEffect(() => {
		debouncedFilterFunction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchKey]);

	return (
		<>
			<TextField
				variant="outlined"
				type="text"
				size="small"
				label={'Search for a Job ID'}
				value={searchKey}
				onChange={(e) => {
					setSearchKey(e.target.value);
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Tooltip
								title={'Clear Search'}
								placement="right"
								arrow
							>
								<IconButton
									className={
										searchKey?.length > 0
											? ''
											: 'button-hidden'
									}
									type="button"
									onClick={() => setSearchKey('')}
									style={{ padding: '0px' }}
								>
									<Close />
								</IconButton>
							</Tooltip>
						</InputAdornment>
					),
				}}
			/>
		</>
	);
}
