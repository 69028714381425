import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuthDispatch, logoutUser } from '../../context/auth';
import { Button, CircularProgress, LinearProgress } from '@material-ui/core';
import FileUploader from './FileUploader';
import TsvDataTable from './TsvDataTable';
import JobConfirmModal from '../Modals/JobConfirmModal';
import JobAlerts from '../Alerts/JobAlerts';
import JobValidator from './JobValidator';

export default function JobUploader({
	operatorData,
	disabledButton,
	legacyKey,
	legacySignature,
}) {
	const dispatch = useAuthDispatch();
	const [file, setFile] = useState('');
	const [fileLength, setFileLength] = useState(0);
	const [fileValidation, setFileValidation] = useState(null);
	const [validating, setValidating] = useState(false);
	const [displayConfirmationModal, setDisplayConfirmationModal] =
		useState(false);
	const [jobStatus, setJobStatus] = useState({
		loading: false,
		state: null,
		jobId: null,
		message: null,
	});
	const [fileUploading, setFileUploading] = useState(false);

	useEffect(() => {
		setFile('');
		setFileValidation(null);
	}, [operatorData]);

	useEffect(() => {
		if (jobStatus.state === 'success') {
			setFile('');
			setFileValidation(null);
		}
	}, [jobStatus]);

	function handleSetFileValidation(validationObject) {
		setFileValidation(validationObject);
	}

	function handleSetValidating(bool) {
		setValidating(bool);
	}

	const handleDisplayConfirmationModal = (boolean) => {
		setDisplayConfirmationModal(boolean);
	};

	const handleSetFileUploading = useCallback((bool) => {
		setFileUploading(bool);
	}, []);

	const handleSetFile = useCallback((fileData) => {
		setFile(fileData);
		setJobStatus({
			state: null,
			jobId: null,
			message: null,
		});
		setFileLength(fileData?.data?.length);
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		setJobStatus({
			loading: true,
			state: null,
			jobId: null,
			message: null,
		});
		const updatedFileData = file.data.map((file) => {
			const newFile = {};
			Object.keys(file).forEach((key) => {
				if (file[key] !== null && file[key] !== '') {
					newFile[key] = file[key];
				} else {
					newFile[key] = null;
				}
			});

			return newFile;
		});

		const email = sessionStorage.getItem('email');
		axios
			.post(
				process.env.REACT_APP_USE_LOCAL_WORKER === 'false'
					? operatorData.endpoint
					: 'jobs/create',
				{
					email: email,
					fileData: updatedFileData,
					fileName: file.metaData.name,
					fileSize: file.metaData.size,
					legacyKey: legacyKey,
					legacySignature: legacySignature,
					operatorData: operatorData,
				},
				{ withCredentials: true }
			)
			.then((res) => {
				setJobStatus({
					loading: false,
					state: 'success',
					jobId: res?.data?.data,
					message: res?.data?.message,
				});
			})
			.catch((e) => {
				setJobStatus({
					loading: false,
					state: 'error',
					jobId: null,
					code: e?.response?.status,
					message: e?.response?.data?.message,
				});
				if (e?.response?.status === 401) {
					logoutUser(dispatch);
				}
			});
	};

	return (
		<>
			<FileUploader
				operatorData={operatorData}
				setFileData={handleSetFile}
				handleSetFileUploading={handleSetFileUploading}
				disabled={
					disabledButton ||
					jobStatus.loading ||
					fileUploading ||
					validating
				}
			/>

			<Button
				variant="contained"
				onClick={() => handleDisplayConfirmationModal(true)}
				disabled={
					!fileValidation?.valid ||
					jobStatus.loading ||
					fileUploading ||
					validating
				}
				style={{
					marginTop: '2rem',
					marginBottom: '2rem',
					height: '42px',
				}}
				fullWidth
			>
				{jobStatus.loading ? (
					<>
						<CircularProgress
							size={30}
							style={{
								marginRight: '10px',
							}}
						/>{' '}
						SUBMITTING JOB...
					</>
				) : (
					'SUBMIT JOB'
				)}
			</Button>

			{file?.data && fileValidation?.valid && (
				<JobConfirmModal
					operatorData={operatorData}
					fileName={file.metaData.name}
					fileSize={file.metaData.size}
					fileLength={file.data.length}
					displayModal={displayConfirmationModal}
					handleDisplayModal={handleDisplayConfirmationModal}
					handleJobSubmit={handleSubmit}
				/>
			)}

			{(validating || fileUploading) && (
				<LinearProgress className="progress-linear"></LinearProgress>
			)}
			{file && (
				<>
					<JobValidator
						file={file}
						operatorData={operatorData}
						fileValidation={fileValidation}
						handleSetFileValidation={handleSetFileValidation}
						handleSetValidating={handleSetValidating}
					></JobValidator>

					{file?.data && !validating && (
						<TsvDataTable
							tableData={file.data}
							fileName={file.metaData.name}
							fileSize={file.metaData.size}
						/>
					)}
				</>
			)}

			{jobStatus.state !== null && (
				<JobAlerts
					jobStatus={jobStatus}
					operatorData={operatorData}
					fileLength={fileLength}
				/>
			)}
		</>
	);
}
