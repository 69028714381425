import React, { useState, useEffect } from 'react';
import {
	FormControl,
	CircularProgress,
	TextField,
	Tooltip,
	Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';

export default function FlipbookPicker({ setFlipbookFolders }) {
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState([]);
	const [flipbookFolderIds, setFlipbookFolderIds] = useState([]);

	const onChange = (_, value) => {
		setValues(value);
		if (value.length !== 0) setFlipbookFolders(value);
		else setFlipbookFolders(flipbookFolderIds);
	};

	async function getFlipbookFolders() {
		const res = await axios.get(`/pdfs/flipbookfolders/`);
		return res;
	}

	useEffect(() => {
		let active = true;

		// Reset Values
		clearSelected();
		setLoading(true);

		getFlipbookFolders()
			.then((res) => {
				if (active) {
					const pdfs = res.data.map((r) => r.Title);
					setOptions([].concat.apply([], pdfs));
					setFlipbookFolders([].concat.apply([], pdfs));
					setFlipbookFolderIds([].concat.apply([], pdfs));
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				if (active) setLoading(false);
			});

		return () => {
			active = false;
		};
	}, [setFlipbookFolders]);

	const label = 'Select Flipbook Folders';
	const disabled = loading;
	const tooltipText = loading ? 'Loading Flipbook Folders...' : '';

	const clearSelected = () => {
		setValues([]);
	};

	return (
		<FormControl size="small" variant="outlined">
			<Tooltip arrow placement="top" title={tooltipText}>
				<Autocomplete
					id="flipbook-folder-picker"
					multiple
					style={{ width: '250px' }}
					size="small"
					disabled={disabled}
					loading={loading}
					options={options}
					getOptionLabel={(option) => option.title}
					value={values}
					onChange={onChange}
					limitTags={1}
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<>
							<Checkbox color="primary" checked={selected} />
							{option.title}
						</>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={label}
							variant="outlined"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loading && (
											<CircularProgress
												color="primary"
												size={20}
											/>
										)}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
				/>
			</Tooltip>
		</FormControl>
	);
}
