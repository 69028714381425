import React, { useState } from 'react';
import { Paper, TextField, Button, ButtonGroup } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

export default function QuantityDialog({
	initialQuantity,
	setParamQuantity,
	handleSetShowDialog,
}) {
	const [quantity, setQuantity] = useState(initialQuantity);

	function adjustQuantity(val) {
		setQuantity(parseInt(quantity) + parseInt(val));
	}

	function handleInput(val) {
		if (val === '') {
			setQuantity(val);
		}
		if (val === '0') {
			setQuantity(1);
		}

		const reg = /^[1-9][0-9]*$/;
		if (reg.test(val)) {
			setQuantity(val);
		}
	}

	return (
		<div className="quantity-dialog-containter">
			<Paper className="quantity-dialog">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<TextField
						id="outlined-basic"
						label="Quantity"
						variant="outlined"
						// type="number"
						size="small"
						value={quantity}
						onChange={(e) => handleInput(e.target.value)}
						autoFocus
					/>
					<ButtonGroup
						className={'incremental-button-group'}
						style={{ marginLeft: '15px' }}
					>
						<Button
							disabled={quantity < 2}
							onClick={() => adjustQuantity(-1)}
							className="incremental-button"
						>
							<Remove fontSize="small"></Remove>
						</Button>
						<Button
							onClick={() => adjustQuantity(1)}
							className="incremental-button"
						>
							<Add fontSize="small"></Add>
						</Button>
					</ButtonGroup>
				</div>
				<br />
				<Button
					variant="contained"
					onClick={() => {
						setParamQuantity(parseInt(quantity));
						handleSetShowDialog(false);
					}}
					size="small"
					disabled={!(quantity > 0)}
				>
					OK
				</Button>
				<Button
					variant="outlined"
					size="small"
					style={{ marginLeft: '5px' }}
					onClick={() => handleSetShowDialog(false)}
				>
					Cancel
				</Button>
			</Paper>
		</div>
	);
}
