import React from 'react';
import {
	Button,
	Divider,
	makeStyles,
	Modal,
	Fade,
	IconButton,
} from '@material-ui/core';
import { Help, Info } from '@material-ui/icons';

export default function TipAlertDialog({ buttonText = null, title, children }) {
	const styles = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			{buttonText ? (
				<Button
					className={styles.helpButton}
					color="default"
					onClick={handleClickOpen}
					startIcon={<Help />}
				>
					{buttonText}
				</Button>
			) : (
				<IconButton
					variant="outlined"
					color="default"
					onClick={handleClickOpen}
				>
					<Help></Help>
				</IconButton>
			)}
			<Modal className={styles.modal} open={open} onClose={handleClose}>
				<Fade in={open}>
					<div className={styles.header}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Info
								style={{
									margin: '5px 10px 5px 20px',
									color: '#2196f3',
								}}
							/>

							<h3 id="transition-modal-title">{title}</h3>
						</div>

						<div className={styles.body}>{children}</div>
						<Divider style={{ marginTop: '25px' }} />
						<div className={styles.footer}>
							<Button onClick={handleClose} variant="contained">
								Close
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	helpButton: {
		color: 'rgb(13, 60, 97)',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '15%',
	},
	header: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		wordWrap: 'break-word',
		borderRadius: '5px !important',
		maxWidth: '700px',
		padding: '15px',
		lineHeight: '1.5',
	},
	body: {
		color: 'rgba(0, 0, 0, 0.87)',
		padding: '0px 20px 10px 20px',
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '15px 0px 0px 0px',
	},
}));
