import React from 'react';

function PageNotFound() {
	return (
		<>
			<h1>PAGE NOT FOUND</h1>
		</>
	);
}

export default PageNotFound;
