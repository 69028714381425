import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuthState } from '../context/auth';

const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {
	const user = useAuthState();

	return (
		<Route
			path={path}
			render={(props) =>
				isPrivate && !Boolean(user.loggedIn) ? (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				) : (
					<Component {...props} />
				)
			}
			{...rest}
		/>
	);
};

export default AppRoutes;
