// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../bulksmasher.worker.js';

export default function workerEvent(eventName, eventData) {
	const promise = new Promise((res, rej) => {
		const worker = new Worker();
		worker.postMessage({
			eventName,
			eventData,
		});
		worker.onerror = (err) => {
			console.log(err);
			rej(err);
		};
		worker.onmessage = (event) => {
			res(event.data);
		};
	});
	return promise;
}
