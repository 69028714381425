import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	IconButton,
	Tooltip,
	Button,
	Typography,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import FilterToggleButtons from './FilterToggleButtons';
import ExportToXLSX from '../../components/TemplateExporter/XLSX/ExportToXLSX';
import VirtualizedTable from './VirtualizedTable';

export default function LogTable({ logs, data }) {
	const styles = useStyles();
	const [filterMethod, setFilterMethod] = useState('ALL');
	const [filteredLogs, setFilteredLogs] = useState([]);
	const [fileData, setFileData] = useState(null);
	const [legacyLog, setLegacyLog] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [dimensions, setDimensions] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	useEffect(() => {
		setFileData(null);
		if (!filterMethod || !logs?.length > 0) return;
		if (filterMethod === 'ALL') setFilteredLogs(logs);
		else if (filterMethod === 'ERRORS') {
			setFilteredLogs(logs.filter((log) => log.level === 'ERROR'));
		} else if (filterMethod === 'FATAL ERRORS') {
			setFilteredLogs(logs.filter((log) => log.level === 'FATAL ERROR'));
		}
	}, [filterMethod, logs]);

	const handleSetFilterMethod = (event, method) => {
		setFilterMethod(method);
	};

	useEffect(() => {
		//We do this so the table rerenders and autosizes itself properly.
		const debouncedHandleResize = debounce(function handleResize() {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}, 1000);

		window.addEventListener('resize', debouncedHandleResize);

		return (_) => {
			window.removeEventListener('resize', debouncedHandleResize);
		};
	});

	const exportLogs = () => {
		let exportObject;
		if (legacyLog) {
			exportObject = logs.map((log) => {
				return {
					Details: log.data ? log.data : '',
				};
			});
		} else {
			exportObject = logs.map((log) => {
				return {
					Timestamp: log.timestamp,
					Type: log.level,
					Action: log.operator,
					Message: log.message,
					Details: log.details ? log.details : '',
				};
			});
		}

		setFileData(exportObject);
	};

	const tableHeaders = [
		{ friendlyName: '', name: 'index' },
		{ friendlyName: 'Timestamp', name: 'timestamp' },
		{ friendlyName: 'Type', name: 'level' },
		{ friendlyName: 'Action', name: 'operator' },
		{ friendlyName: 'Message', name: 'message' },
		{ friendlyName: 'Details', name: 'details' },
		// Legacy log header
		{ friendlyName: 'Message', name: 'data' },
	];

	const LogsTable = () => {
		if (filteredLogs.length <= 0) return;
		let headers = new Set();
		filteredLogs.forEach((row) => {
			Object.getOwnPropertyNames(row).forEach((header) =>
				headers.add(header)
			);
		});

		const columns = [...headers].reduce((acc, col) => {
			const formattedHeader = tableHeaders.find(
				(header) => header.name === col
			);

			if (formattedHeader) {
				// pushing index to the beginning
				if (formattedHeader.name === 'index')
					acc.unshift(formattedHeader);
				else {
					if (formattedHeader.name === 'data') setLegacyLog(true);
					acc.push(formattedHeader);
				}
			}
			return acc;
		}, []);

		if (filteredLogs?.length > 0) {
			return (
				<VirtualizedTable
					filteredLogs={filteredLogs}
					columns={columns}
				/>
			);
		} else {
			return <Button disabled>No logs to display.</Button>;
		}
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginLeft: 'auto',
					// justifyContent: 'space-between',
				}}
			>
				<div>
					<h3
						style={{
							display: 'flex',
							alignItems: 'center',
							fontSize: '1.3em',
						}}
					>
						Job Logs
						{legacyLog && (
							<Typography className={styles.legacy} disabled>
								{' '}
								Legacy Logs
							</Typography>
						)}
					</h3>
				</div>

				<div style={{ display: 'flex', marginLeft: 'auto' }}>
					{!legacyLog && (
						<FilterToggleButtons
							filterMethod={filterMethod}
							handleSetFilterMethod={handleSetFilterMethod}
						/>
					)}

					<Tooltip title="Export Logs" placement="top" arrow>
						<IconButton
							type="button"
							onClick={() => exportLogs()}
							disabled={filteredLogs?.length === 0}
						>
							<GetApp
								style={
									filteredLogs?.length === 0
										? { color: 'rgba(0, 0, 0, 0.30)' }
										: { color: 'rgba(0, 0, 0, 0.87)' }
								}
							/>
						</IconButton>
					</Tooltip>
				</div>
			</div>

			<div>
				{filteredLogs?.length > 0 ? (
					<LogsTable />
				) : (
					<Button
						disabled
						style={{
							position: 'absolute',
							padding: '25px 0px 0px 0px',
						}}
					>
						No{' '}
						{filterMethod === 'ALL'
							? 'logs'
							: filterMethod?.toLowerCase()}{' '}
						to display.
					</Button>
				)}
			</div>

			{fileData && filteredLogs?.length > 0 && (
				<ExportToXLSX
					fileData={fileData}
					fileName={`${data.jobId} Log.xlsx`}
				/>
			)}
		</>
	);
}

const useStyles = makeStyles({
	legacy: {
		backgroundColor: '#cfd8dc90',
		opacity: 0.5,
		marginLeft: '10px',
		padding: '2px 5px 2px 5px',
		fontSize: '12px',
		borderRadius: '12px',
	},
});

function debounce(fn, ms) {
	let timer;
	return (_) => {
		clearTimeout(timer);
		timer = setTimeout((_) => {
			timer = null;
			fn.apply(this, arguments);
		}, ms);
	};
}
