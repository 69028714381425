import React from 'react';
import { Typography } from '@material-ui/core';

export default function OperatorHeader({ name, description }) {
	return (
		<>
			<h3>{name}</h3>
			<Typography>{description}</Typography>
			<br />
		</>
	);
}
