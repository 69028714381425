import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthDispatch, logoutUser } from '../../context/auth/index';
import LogTable from './LogTable';
import LoadingMessage from '../../components/LoadingMessage';
import {
	IconButton,
	makeStyles,
	Typography,
	CircularProgress,
	Link,
	Tooltip,
	Divider,
	Chip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { dateFormat, fileSizeFormat } from '../../utils/Formatters/';
import ExportToXLSX from '../../components/TemplateExporter/XLSX/ExportToXLSX';

export default function JobsDetails({ data, handleShowDrawer }) {
	const styles = useStyles();
	const dispatch = useAuthDispatch();
	const [loadingLogs, setLoadingLogs] = useState(false);
	const [loadingSource, setLoadingSource] = useState(false);
	const [logs, setLogs] = useState([]);
	const [sourceFile, setSourceFile] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		getLogs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	async function getLogs() {
		setLoadingLogs(true);
		axios
			.get(`jobs/read-log/${data.jobId}`)
			.then((res) => {
				setLoadingLogs(false);
				console.debug(res.data);
				setLogs(res.data.data);
			})
			.catch((e) => {
				setLoadingLogs(false);
				console.debug(e);
				setErrorMessage(e?.response?.data?.message);
				if (e.response.status === 401) {
					logoutUser(dispatch);
				}
			});
	}

	async function getSourceFile() {
		setLoadingSource(true);
		axios
			.get(`jobs/read-source-file/${data.jobId}`)
			.then((res) => {
				setLoadingSource(false);
				setSourceFile(res.data.data);
			})
			.catch((e) => {
				setLoadingSource(false);
				setErrorMessage(e?.response?.data?.message);
				if (e.response.status === 401) {
					logoutUser(dispatch);
				}
			});
	}

	return (
		<div
			style={{
				padding: '1rem',
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div>
					<Typography
						style={{
							display: 'flex',
							alignItems: 'center',
							fontSize: '1.3em',
						}}
					>
						{data.jobId}
						<Chip
							color={
								data.status === 'Warning'
									? 'primary'
									: data.status === 'Failed'
									? 'secondary'
									: 'default'
							}
							size={'small'}
							style={{
								marginLeft: '10px',
							}}
							label={data.status}
						/>
					</Typography>
				</div>
				<IconButton
					type="button"
					onClick={() => handleShowDrawer(false)}
				>
					<Close />
				</IconButton>
			</div>
			<div className={styles.subContainer}>
				<Typography className={styles.label}>
					Action: {data.operator} {data.category}
				</Typography>
				<Typography className={styles.label}>
					Finished On: {dateFormat(data)} (finished in {data.runtime})
				</Typography>
				<Typography className={styles.label}>
					Executions: {data.executions}
				</Typography>

				{data.errors !== '' && (
					<Typography className={styles.label}>
						Errors: {data.errors}
					</Typography>
				)}

				{data.email !== '' && (
					<Typography className={styles.label}>
						Email Recipient: {data.email}
					</Typography>
				)}
				<Typography
					style={{
						display: 'flex',
						alignItems: 'center',
						padding: '20px 10px 0px 0px',
						whiteSpace: 'nowrap',
					}}
				>
					<span className={styles.label}>Source File: </span>
					<span
						className={styles.label}
						style={{
							display: 'flex',
							alignItems: 'center',
							marginLeft: '5px',
						}}
					>
						<Tooltip
							title="Export Source File"
							placement="top"
							arrow
						>
							<Link
								component="button"
								variant="body2"
								onClick={getSourceFile}
								style={
									loadingSource
										? {
												pointerEvents: 'none',
												opacity: '0.5',
										  }
										: {}
								}
							>
								{data.fileName === 'Auto-generated'
									? 'Auto-generated by Bulk Smasher'
									: data.fileName}
								{data.fileName !== 'Auto-generated' &&
									` - ${fileSizeFormat(data)}`}
							</Link>
						</Tooltip>
					</span>
					{loadingSource && (
						<CircularProgress
							size={20}
							style={{ marginLeft: '10px' }}
						/>
					)}
				</Typography>
			</div>

			<Divider style={{ margin: '15px 0px 15px 0px' }} />

			<div>
				<div>
					{loadingLogs ? (
						<LoadingMessage>Loading Logs...</LoadingMessage>
					) : logs.length > 0 ? (
						<LogTable
							data={data}
							logs={logs}
							handleShowDrawer={handleShowDrawer}
						/>
					) : (
						<div style={{ direction: 'ltr' }}>{errorMessage}</div>
					)}
				</div>
			</div>

			{sourceFile && (
				<ExportToXLSX
					fileData={sourceFile}
					fileName={`${data.jobId} File.xlsx`}
				/>
			)}
		</div>
	);
}

const useStyles = makeStyles({
	label: {
		fontSize: '15px',
	},
	data: {
		fontSize: '15px',
	},
	subContainer: {
		padding: '10px 0px 10px 0px',
	},
	typographyStyle: {
		color: '#0e4c8a',
	},
});
