import React from 'react';
import { useAuthState } from '../../context/auth/';

export default function DevLocal() {
	const { gitBranch, gitCommitHash, version } = useAuthState();

	return (
		<div
			style={{
				position: 'fixed',
				left: '10px',
				bottom: '0px',
				color: 'blue',
				fontSize: '13px',
				padding: 0,
			}}
		>
			{gitBranch && gitCommitHash && (
				<p>
					<strong>Branch:</strong>{' '}
					<code>
						{gitBranch} ({gitCommitHash})
					</code>
				</p>
			)}
			<p>
				<strong>Version:</strong> <code>v{version}</code>
			</p>
			<p>
				<strong>Environment:</strong>{' '}
				<code>{process.env.REACT_APP_ENVIRONMENT}</code>
			</p>
			<p>
				<strong>Local Worker:</strong>{' '}
				<code>{process.env.REACT_APP_USE_LOCAL_WORKER}</code>
			</p>
		</div>
	);
}
