import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	AccordionDetails,
	Divider,
	Typography,
	Grid,
	Button,
	Link,
} from '@material-ui/core';
import TemplateGeneratorParameter from './TemplateGeneratorParameter';
import JobAlerts from '../Alerts/JobAlerts';
import TemplateGeneratorDownload from './TemplateGeneratorDownload';
import InfoAlert from '../Alerts/InfoAlert';
import AsterixParameter from './AsterixParameter';

const TemplateGenerator = ({ operatorData }) => {
	const styles = useStyles();
	const [hasHybrid, setHasHybrid] = useState(false);
	const [optionalHeaderCount, setOptionalHeaderCount] = useState(null);
	const [templateParams, setTemplateParams] = useState([]);
	const [defaultHeaderState, setDefaultHeaderState] = useState(null);
	const [jobStatus, setJobStatus] = useState({
		loading: false,
		state: false,
		code: null,
		message: null,
	});

	useEffect(() => {
		//Check if param has hybrid
		const hasHybrid = operatorData.params.some((param) =>
			param.name.includes('*')
		);
		if (hasHybrid) setHasHybrid(true);

		//Check # of optional headers
		const optionalHeaderCount = operatorData.params.reduce((acc, param) => {
			if (!param.required) acc++;
			return acc;
		}, 0);
		setOptionalHeaderCount(optionalHeaderCount);

		//Set Params State
		let defaultHeaderState = null;

		//default selected is false if there are required headers
		const hasRequiredHeaders = operatorData.params.find(
			(param) => param.required
		);
		defaultHeaderState = !hasRequiredHeaders;

		const params = operatorData.params.map((param) => {
			return {
				...param,
				selected: defaultHeaderState,
			};
		});
		setDefaultHeaderState(defaultHeaderState);
		setTemplateParams(params);
	}, [operatorData.params, operatorData.operator]);

	const setAllParams = (bool) => {
		templateParams.forEach((param) => {
			param.selected = bool;
		});
		setTemplateParams([...templateParams]);
	};

	const handleToggleParam = (paramName) => {
		const toggleParam = templateParams.find(
			(param) => param.name === paramName
		);
		toggleParam.selected = !toggleParam.selected;
		setTemplateParams([...templateParams]);
	};

	const handleSetJobStatus = (data) => {
		setJobStatus(data);
	};

	const showFilterRedirectInfo =
		(operatorData.category === 'Filters' ||
			operatorData.category === 'Redirects') &&
		(operatorData.operator === 'Create' ||
			operatorData.operator === 'Update');

	const handleSetParamQuantity = (paramName, quantity) => {
		const param = templateParams.find((param) => param.name === paramName);
		param.quantity = quantity;
		setTemplateParams([...templateParams]);
	};

	return (
		<div className={styles.root}>
			<AccordionDetails className={styles.details}>
				<div className={styles.column}>
					<div
						style={{
							display: 'flex',
						}}
					>
						<Typography>
							{operatorData.templateMessage}
							{defaultHeaderState
								? '\n\nAll available optional headers for this job template will be included by default. Uncheck any optional header to remove it from the downloaded job template.'
								: optionalHeaderCount > 0
								? '\n\nYou can also add optional headers to the job template to configure various additional parameters. Select an optional header to include it in the downloaded job template.'
								: '\n\nThere are no optional headers for this job template.'}
							{hasHybrid &&
								'\n\nHeaders marked with an asterisk (*) can be added to a job template multiple times to specify multiple values for the corresponding parameter.'}
						</Typography>
					</div>
					<br />
					{showFilterRedirectInfo && (
						<div>
							<InfoAlert title="TIP">
								<div>
									This job template requires complex values
									that are difficult to create manually. To
									create these values, we strongly recommend
									following the process outlined in{' '}
									<Link
										href="https://help.uberflip.com/hc/en-us/articles/4413439856781"
										target="_blank"
										rel="noopener noreferrer"
									>
										this article
									</Link>
									.
								</div>
							</InfoAlert>
							<br />
						</div>
					)}

					<Divider />
					<br />
					<Grid container spacing={2}>
						{templateParams.map((param) => {
							if (param.name.includes('*')) {
								return (
									<Grid
										item
										key={`${operatorData.operator}${param.name}`}
									>
										<AsterixParameter
											param={param}
											handleToggleParam={
												handleToggleParam
											}
											handleSetParamQuantity={
												handleSetParamQuantity
											}
										/>
									</Grid>
								);
							} else {
								return (
									<Grid
										item
										key={`${operatorData.operator}${param.name}`}
									>
										<TemplateGeneratorParameter
											param={param}
											handleToggleParam={
												handleToggleParam
											}
										/>
									</Grid>
								);
							}
						})}
					</Grid>
					<br />
					{optionalHeaderCount > 1 && (
						<div style={{ display: 'flex' }}>
							<Button
								size="small"
								variant="contained"
								style={{
									marginRight: '0.5rem',
								}}
								onClick={() => setAllParams(true)}
							>
								Select All
							</Button>
							<Button
								size="small"
								variant="outlined"
								color="default"
								component="label"
								onClick={() => setAllParams(false)}
							>
								Clear
							</Button>
						</div>
					)}
				</div>
			</AccordionDetails>
			<Divider />
			<TemplateGeneratorDownload
				operatorData={operatorData}
				selectedParams={templateParams?.filter(
					(param) => param.selected || param.required
				)}
				jobStatus={jobStatus}
				handleSetJobStatus={handleSetJobStatus}
			/>
			{jobStatus.state === 'read error' && (
				<JobAlerts jobStatus={jobStatus} operatorData={operatorData} />
			)}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		flexGrow: 1,
	},
	column: {
		flexBasis: '100%',
	},
	details: {
		display: 'flex',
		alignItems: 'center',
	},
	formControl: {
		marginRight: '0px',
		paddingTop: '20px',
	},
}));

export default TemplateGenerator;
