import React, { useState, useEffect } from 'react';
import {
	Card,
	IconButton,
	Tooltip,
	Button,
	useMediaQuery,
	Drawer,
	Link,
} from '@material-ui/core';
import { ArrowDownward, GetApp } from '@material-ui/icons';
import ExportToXLSX from '../../components/TemplateExporter/XLSX/ExportToXLSX';
import DataTable from 'react-data-table-component';
import JobsDetails from './JobsDetails';
import FilterComponent from './FilterComponent';
import { useAuthState } from '../../context/auth';
import {
	dateFormat,
	statusFormat,
	fileSizeFormat,
} from '../../utils/Formatters';
import '../../styles/app.css';
import useIFramed from '../../hooks/useIFrame';

const hiddenHeaders = ['accountId', 'endpoint'];

const JobsTable = ({ tableData, initialTableData, handleSetTableData }) => {
	const [tableColumns, setTableColumns] = useState([]);
	const { accountId, companyName } = useAuthState();
	const [fileData, setFileData] = useState(null);
	const [showDrawer, setShowDrawer] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const shortenLabel = useMediaQuery('(max-width:750px)');
	const tableHeaders = [
		{ friendlyName: 'Job ID', name: 'jobId' },
		{ friendlyName: 'Status', name: 'status' },
		{ friendlyName: 'Date', name: 'date' },
		{ friendlyName: 'Resource', name: 'category' },
		{ friendlyName: 'Action', name: 'operator' },
	];
	const isIFramed = useIFramed();

	useEffect(() => {
		if (tableData.length === 0) {
			setTableColumns([]);
			return;
		}

		const columns = tableHeaders.map((header) => {
			return {
				name: header.friendlyName,
				selector: header.name,
				sortable: true,
				omit: hiddenHeaders.includes(header),
				format: header.name === 'date' ? dateFormat : '',
				hide:
					header.name === 'status' || header.name === 'jobId'
						? ''
						: 740,
				cell:
					header.name === 'status'
						? statusFormat
						: header.name === 'jobId'
						? jobIdFormat
						: '',
				minWidth:
					header.name === 'date'
						? '170px'
						: header.name === 'jobId'
						? '180px'
						: header.name === 'status'
						? '150px'
						: '',
			};
		});
		setTableColumns(columns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableData]);

	const exportReports = () => {
		const exportedData = tableData.map((row) => {
			return {
				...row,
				date: dateFormat(row),
				fileSize: fileSizeFormat(row),
			};
		});

		setFileData(exportedData);
	};

	const handleShowDrawer = (boolean, row = null) => {
		setShowDrawer(boolean);
		if (!boolean) setSelectedRow(null);
		else setSelectedRow(row);
	};

	const actions = (
		<>
			<FilterComponent
				initialTableData={initialTableData}
				handleSetTableData={handleSetTableData}
			/>
			<Tooltip title="Export Jobs Report" placement="top" arrow>
				<IconButton
					onClick={() => {
						exportReports();
					}}
				>
					<GetApp style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
				</IconButton>
			</Tooltip>
		</>
	);
	const sortIcon = <ArrowDownward />;

	const conditionalRowStyles = [
		{
			when: (row) => row === selectedRow,
			style: {
				backgroundColor: '#cce5ff',
				'&:hover': {
					backgroundColor: '#cce5ff',
				},
			},
		},
		{
			when: (row) =>
				row.status === 'Processing' || row.status === 'Queued',
			style: {
				opacity: 0.7,
				'&:hover': {
					cursor: 'not-allowed',
				},
			},
		},
	];

	const paginationComponentOptions = { noRowsPerPage: true };

	const NoResultsFound = () => (
		<div className="custom-file form-group">
			<Button color="default" disabled>
				No results found.
			</Button>
		</div>
	);

	const jobIdFormat = (row) => {
		if (row.status === 'Processing' || row.status === 'Queued')
			return row.jobId;
		else
			return (
				<Link
					onClick={() => {
						handleShowDrawer(true, row);
					}}
				>
					{row.jobId}
				</Link>
			);
	};

	return (
		<div style={isIFramed ? { width: '98vw' } : {}}>
			<Card
				id="drawer-container"
				style={{
					position: 'relative',
					minHeight: '60vh',
					marginRight: '40px',
				}}
			>
				<DataTable
					title={
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								paddingLeft: '0px',
							}}
						>
							{shortenLabel ? `Jobs` : `${companyName} Jobs`}
						</div>
					}
					columns={tableColumns}
					data={tableData}
					dense
					keyField="jobId"
					striped
					highlightOnHover
					responsive
					pagination
					paginationPerPage={20}
					paginationRowsPerPageOptions={[20, 50, 100]}
					defaultSortField="date"
					actions={actions}
					sortIcon={sortIcon}
					noDataComponent={
						tableData.length === 0 && <NoResultsFound />
					}
					pointerOnHover
					onRowClicked={(row) => {
						if (
							row.status === 'Processing' ||
							row.status === 'Queued'
						) {
							handleShowDrawer(false);
						} else {
							handleShowDrawer(true, row);
						}
					}}
					defaultSortAsc={false}
					conditionalRowStyles={conditionalRowStyles}
					paginationComponentOptions={paginationComponentOptions}
				/>

				{selectedRow && (
					<Drawer
						id="drawer-container"
						open={showDrawer}
						onClose={() => {
							handleShowDrawer(false);
						}}
						PaperProps={{
							style: {
								position: 'absolute',
								width: '80%',
								// overflowY: 'scroll',
							},
						}}
						BackdropProps={{
							style: {
								position: 'absolute',
								backgroundColor: 'rgba(0, 0, 0, 0.1)',
							},
						}}
						ModalProps={{
							container:
								document.getElementById('drawer-container'),
							style: { position: 'absolute' },
						}}
						variant="temporary"
						anchor="right"
						disableEnforceFocus
					>
						<JobsDetails
							data={selectedRow}
							handleShowDrawer={handleShowDrawer}
						/>
					</Drawer>
				)}
			</Card>

			{fileData && (
				<ExportToXLSX
					fileData={fileData}
					fileName={`${accountId} Job Report.xlsx`}
				/>
			)}
		</div>
	);
};

export default JobsTable;
