import { CircularProgress } from '@material-ui/core';
import {
	VerticalAlignTopOutlined,
	ErrorOutlined,
	CheckCircle,
	Warning,
} from '@material-ui/icons';

export default function statusFormat(row) {
	if (row.status === 'Queued') {
		return (
			<div className="table-status-row">
				<VerticalAlignTopOutlined className="table-status-image" />{' '}
				Queued
			</div>
		);
	} else if (row.status === 'Processing') {
		return (
			<div className="table-status-row">
				<CircularProgress
					color="primary"
					className="table-status-image processing-icon"
					size={20}
					style={{ marginLeft: '2px', marginRight: '12px' }}
				/>{' '}
				Processing
			</div>
		);
	} else if (row.status === 'Completed') {
		return (
			<div className="table-status-row ">
				<CheckCircle className="table-status-image completed-icon" />{' '}
				Completed
			</div>
		);
	} else if (row.status === 'Warning') {
		return (
			<div className="table-status-row ">
				<Warning className="table-status-image warning-icon" /> Warning
			</div>
		);
	} else if (row.status === 'Failed') {
		return (
			<div className="table-status-row ">
				<ErrorOutlined className="table-status-image failed-icon" />{' '}
				Failed
			</div>
		);
	} else return <div>{row.status}</div>;
}
