import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../../bulksmasher.worker.js';

export default function JobValidator({
	file,
	operatorData,
	fileValidation,
	handleSetFileValidation,
	handleSetValidating,
}) {
	const worker = new Worker();

	useEffect(() => {
		if (!file) return;
		initWorker();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file]);

	function initWorker() {
		handleSetFileValidation(null);
		handleSetValidating(true);
		worker.postMessage({
			eventName: 'Validate Job File',
			eventData: {
				file,
				operatorData,
			},
		});

		worker.onerror = (err) => {
			console.log(err);
			handleSetValidating(false);
		};
		worker.onmessage = (event) => {
			handleSetFileValidation({ ...event?.data, validating: false });
			handleSetValidating(false);
		};
	}

	return (
		<>
			{fileValidation?.valid === false && (
				<>
					<Typography className="file-validation-phrase">
						The uploaded file can’t be submitted due to the
						following errors:
					</Typography>
					<Typography className="file-validation-message">
						{fileValidation.message}
					</Typography>
				</>
			)}
		</>
	);
}
