import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
	Button,
	CircularProgress,
	Typography,
	TextField,
	Checkbox,
} from '@material-ui/core';
import { logoutUser, useAuthDispatch } from '../../../../context/auth';
import JobConfirmModal from '../../../../components/Modals/JobConfirmModal';
import JobAlerts from '../../../../components/Alerts/JobAlerts';
import SocketDataExport from '../../../../components/SocketDataExport/SocketDataExport';
import TableAccordion from '../../../../components/TableAccordion';

const RemoveCategory = ({ operatorData }) => {
	const dispatch = useAuthDispatch();
	const [fileData, setFileData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [affectedItems, setAffectedItems] = useState(null);
	const [displayModal, setDisplayModal] = useState(false);
	const [delimiter, setDelimiter] = useState('');
	const [tagFilter, setTagFilter] = useState('');
	const [tagGroupOnly, setTagGroupOnly] = useState(false);
	const [jobStatus, setJobStatus] = useState({
		loading: false,
		state: null,
		jobId: null,
		message: null,
	});

	useEffect(() => {
		if (fileData.length === 0) return;
		const renamedTags = fileData.reduce((acc, tag) => {
			if (delimiter && tag.name.includes(delimiter)) {
				if (tagFilter !== '' && tag.name.includes(tagFilter))
					return acc;

				if (tagGroupOnly && tag.tag_group_name === 'CONTENT')
					return acc;

				const nameSplit = tag.name.split(delimiter);
				const newName = nameSplit[nameSplit.length - 1].trim();

				acc.push({
					tag_id: tag.id,
					tag_name_old: tag.name,
					tag_name: newName,
					tag_group: tag.tag_group_name,
				});
			}
			return acc;
		}, []);

		setFilteredData(renamedTags);
		setAffectedItems(renamedTags.length);
	}, [fileData, delimiter, tagFilter, tagGroupOnly]);

	useEffect(() => {
		if (jobStatus.state === 'success') {
			setFileData([]);
		}
	}, [jobStatus]);

	const handleSetFileData = (data) => {
		setFileData(data);
	};

	const handleDisplayModal = (boolean) => {
		setDisplayModal(boolean);
	};

	const handleSetJobStatus = (data) => {
		setJobStatus(data);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setJobStatus({
			loading: true,
			state: null,
			jobId: null,
			message: null,
		});

		const email = sessionStorage.getItem('email');

		axios
			.post(
				process.env.REACT_APP_USE_LOCAL_WORKER === 'false'
					? operatorData.endpoint
					: 'jobs/create',
				{
					email: email,
					fileData: filteredData,
					operatorData: operatorData,
				},
				{ withCredentials: true }
			)
			.then((res) => {
				setJobStatus({
					loading: false,
					state: 'success',
					jobId: res?.data?.data,
					message: res?.data?.message,
				});
			})
			.catch((e) => {
				console.log(e);
				setJobStatus({
					loading: false,
					state: 'error',
					jobId: null,
					code: e?.response?.status,
					message: e?.response?.data?.message,
				});
				if (e.response.status === 401) {
					logoutUser(dispatch);
				}
			});
	};

	return (
		<>
			{jobStatus.state !== 'success' && (
				<>
					<TextField
						variant="outlined"
						size="small"
						type="text"
						label="Separator character"
						value={delimiter}
						onChange={(e) => setDelimiter(e.target.value)}
						style={{ width: '350px' }}
					/>
					<br />
					<br />
					<TextField
						variant="outlined"
						size="small"
						type="text"
						label="Exclude tags that contain (case sensitive)"
						value={tagFilter}
						onChange={(e) => setTagFilter(e.target.value)}
						style={{ width: '350px' }}
					/>
					<br />
					<br />
					<div>
						Only rename tags that belong to tag groups
						<Checkbox
							color="primary"
							checked={tagGroupOnly}
							onChange={() => setTagGroupOnly((prev) => !prev)}
						/>
					</div>
				</>
			)}

			{jobStatus.state === 'success' ? (
				<></>
			) : fileData.length === 0 ? (
				<SocketDataExport
					operatorData={operatorData}
					handleSetFileData={handleSetFileData}
					jobStatus={jobStatus}
					handleSetJobStatus={handleSetJobStatus}
					buttonText={`Find ${operatorData.category}`}
					initializingText={`Searching ${operatorData.category}`}
					downloadingText={`Extracting ${operatorData.category}`}
					disabled={!delimiter}
				></SocketDataExport>
			) : (
				<>
					{filteredData?.length === 0 && (
						<>
							<br />
							{delimiter ? (
								<Typography>
									{`Found ${filteredData.length} tags (out of ${fileData.length} total tags) to rename.`}
								</Typography>
							) : (
								<Typography>
									Please enter a separator character.
								</Typography>
							)}

							<br />
						</>
					)}
					{filteredData?.length > 0 && (
						<div>
							<TableAccordion
								label={`Found ${filteredData.length} tags (out of ${fileData.length} total tags) to rename (click to view)`}
								data={filteredData}
							/>
							<Button
								variant="contained"
								onClick={() => handleDisplayModal(true)}
								disabled={
									jobStatus.loading || !(affectedItems > 0)
								}
								style={{
									marginTop: '2rem',
									marginBottom: '2rem',
									height: '42px',
								}}
								fullWidth
							>
								{jobStatus.loading ? (
									<>
										<CircularProgress
											size={30}
											style={{
												marginRight: '10px',
											}}
										/>{' '}
										SUBMITTING JOB...
									</>
								) : (
									'SUBMIT JOB'
								)}
							</Button>
						</div>
					)}
					<JobConfirmModal
						operatorData={operatorData}
						displayModal={displayModal}
						fileLength={filteredData.length}
						handleDisplayModal={handleDisplayModal}
						handleJobSubmit={handleSubmit}
					/>
				</>
			)}

			{jobStatus.state !== null && (
				<JobAlerts
					jobStatus={jobStatus}
					operatorData={operatorData}
					fileLength={affectedItems}
				/>
			)}
		</>
	);
};

export default RemoveCategory;
