import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	makeStyles,
	Avatar,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { useAuthState } from '../../context/auth';
import logo from '../../images/bts_logo.svg';
import AccountMenu from './AccountMenu';
import useIFramed from '../../hooks/useIFrame';
import GetHelp from './GetHelp';

export default function Navbar() {
	const styles = useStyles();
	const { loggedIn } = useAuthState();
	const isIFramed = useIFramed();
	const noBrandkWidth = isIFramed ? '812px' : '750px';
	const noBrand = useMediaQuery(`(max-width:${noBrandkWidth})`);

	if (!loggedIn) return <></>;

	return (
		<nav className={isIFramed ? 'navbar-iframed' : 'navbar'}>
			<Avatar className={styles.avatar} alt="avatar" src={logo} />{' '}
			{!noBrand && (
				<Typography className={styles.brand}>
					BULK TASK SMASHER
				</Typography>
			)}
			<NavLink
				exact
				activeClassName="navbar__link--active"
				className="navbar__link"
				to="/"
			>
				{'RUN JOBS'}
			</NavLink>
			<NavLink
				activeClassName="navbar__link--active"
				className="navbar__link"
				to="/jobs"
			>
				{'VIEW JOBS'}
			</NavLink>
			{!isIFramed ? <AccountMenu /> : <GetHelp />}
		</nav>
	);
}

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: theme.spacing(6),
		height: theme.spacing(6),
	},
	brand: {
		cursor: 'default',
		display: 'inline-block',
		paddingLeft: '1.4rem',
		marginRight: '1rem',
		fontSize: '1.25rem',
		lineHeight: 'inherit',
		whiteSpace: 'nowrap',
		letterSpacing: '0.1em',
	},
}));
