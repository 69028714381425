import React from 'react';
import {
	makeStyles,
	Modal,
	Fade,
	Button,
	Typography,
} from '@material-ui/core/';
import { Warning } from '@material-ui/icons';

export default function CancelDownloadModal({
	handleDisplayModal,
	displayCancelledModal,
	handleDisplayCancelDownload,
	handleSetJobStatus,
}) {
	const styles = useStyles();

	return (
		<Modal
			closeAfterTransition
			className={styles.modal}
			open={displayCancelledModal}
		>
			<Fade in={displayCancelledModal}>
				<div className={styles.header}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Warning style={{ margin: '10px', color: '#a94442' }} />
						<h3>Cancel this download?</h3>
					</div>
					<div className={styles.body}>
						<Typography>
							The file will be deleted. If you want to download it
							later, you will need to restart the export process.
						</Typography>
						<div className={styles.footer}>
							<Button
								variant="outlined"
								className={styles.cancelButton}
								onClick={() => {
									handleDisplayCancelDownload(false);
								}}
							>
								No, take me back
							</Button>
							<Button
								variant="contained"
								size="medium"
								onClick={() => {
									handleDisplayModal(false);
									handleDisplayCancelDownload(false);
									handleSetJobStatus({ loading: false });
								}}
							>
								Yes, cancel the download
							</Button>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '15%',
	},
	header: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		wordWrap: 'break-word',
		borderRadius: '5px !important',
		maxWidth: '700px',
		padding: '15px',
		lineHeight: '1.5',
	},
	body: {
		padding: '0px 20px 20px 20px',
		fontSize: '12px',
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '15px 0px 0px 0px',
	},
	cancelButton: {
		textTransform: 'none',
		marginRight: '10px',
	},
}));
