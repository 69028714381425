import React, { useState, useEffect } from 'react';
import {
	FormControl,
	CircularProgress,
	TextField,
	Tooltip,
	Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import asyncPool from 'tiny-async-pool';

export default function StreamPicker({ setStreams, hubIds }) {
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState([]);
	const noHubsSelected = !hubIds?.length;

	const onChange = (_, value) => {
		setValues(value);
		setStreams(value || []);
	};

	async function getStreams(hubIds) {
		const resArray = await asyncPool(10, hubIds, (hubId, hubIds) => {
			const res = axios.get(`/hubs/${hubId}/streams`);
			return res;
		});

		const onlySourceStream = resArray
			.map((res) => res.data.streams)
			.flat()
			.filter((stream) =>
				stream.type !== 'custom' && stream.type !== 'targeted'
					? true
					: false
			);

		return onlySourceStream;
	}

	useEffect(() => {
		let active = true;

		// Reset Values
		clearSelected();
		setStreams([]);
		setOptions([]);

		if (noHubsSelected) {
			setLoading(false);
			return;
		}
		setLoading(true);

		getStreams(hubIds)
			.then((streams) => {
				if (active) {
					setOptions(streams);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				if (active) setLoading(false);
			});

		return () => {
			active = false;
		};
	}, [setStreams, hubIds, noHubsSelected]);

	const label = 'Select Stream(s)';
	const disabled = noHubsSelected || loading;
	const tooltipText = noHubsSelected
		? 'Select a Hub first.'
		: loading
		? 'Loading streams...'
		: '';

	const clearSelected = () => {
		setValues([]);
	};

	return (
		<FormControl size="small" variant="outlined">
			<Tooltip arrow placement="top" title={tooltipText}>
				<Autocomplete
					id="stream-picker"
					multiple
					style={{ width: '250px' }}
					size="small"
					disabled={disabled}
					loading={loading}
					options={options}
					groupBy={(option) => option.hub_id}
					getOptionLabel={(option) => option.name}
					value={values}
					onChange={onChange}
					limitTags={1}
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<>
							<Checkbox color="primary" checked={selected} />
							{option.name}
						</>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={label}
							variant="outlined"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loading && (
											<CircularProgress
												color="primary"
												size={20}
											/>
										)}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
				/>
			</Tooltip>
		</FormControl>
	);
}
