import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import SheetsConfirmModal from '../Modals/SheetConfirmModal';
import FileDragAndDrop from './FileDragAndDrop';
import workerEvent from '../../hooks/workerEvent';

const FileUploader = ({
	operatorData,
	setFileData,
	handleSetFileUploading,
	disabled = false,
}) => {
	const [displaySheetsModal, setDisplaySheetsModal] = useState(false);
	const [tempFile, setTempFile] = useState(null);
	const [tempWorkbook, setTempWorkbook] = useState(null);
	const [sheets, setSheets] = useState({});
	const [selectedSheet, setSelectedSheet] = useState('');
	const [isIncorrect, setIsIncorrect] = useState(false);

	const handleSetDisplaySheetsModal = (boolean) => {
		setDisplaySheetsModal(boolean);
	};

	const handleSetSelectedSheet = (sheet) => {
		setSelectedSheet(sheet);
	};

	const readXLSXFile = async (file) => {
		handleSetFileUploading(true);

		try {
			if (
				file.type !==
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			) {
				setIsIncorrect(true);
			}
			const excelFile = await readFileData(file);
			const xlsxWorkbook = await workerEvent('Read XLSX Workbook', {
				excelFile,
			});

			//Remove the "how to" sheet if it exists
			deleteWorksheet(xlsxWorkbook, 'How To Use This Template');

			// Checking if provided sheet has more than 1 sheet
			if (Object.keys(xlsxWorkbook.Sheets).length > 1) {
				setTempWorkbook(xlsxWorkbook);
				setSheets(xlsxWorkbook.Sheets);
				setDisplaySheetsModal(true);
				setTempFile(file);
				setIsIncorrect(false);
			} else {
				// Get selected worksheet
				const sheetName = xlsxWorkbook.SheetNames[0];
				const sheet = xlsxWorkbook.Sheets[sheetName];
				const formattedSheet = await workerEvent('Format XLSX Sheet', {
					sheet,
					operatorData,
				});
				setFileData({
					data: formattedSheet,
					metaData: {
						name: file.name,
						type: file.type,
						size: file.size,
						lastModifiedDate: file.lastModifiedDate,
					},
				});
				setIsIncorrect(false);
			}
		} catch (error) {
			console.log(error);
		}
		handleSetFileUploading(false);
	};

	const readMultiXLSXFile = async () => {
		try {
			const sheet = tempWorkbook.Sheets[selectedSheet];
			const formattedSheet = await workerEvent('Format XLSX Sheet', {
				sheet,
				operatorData,
			});
			setFileData({
				data: formattedSheet,
				metaData: {
					name: tempFile.name,
					type: tempFile.type,
					size: tempFile.size,
					lastModifiedDate: tempFile.lastModifiedDate,
				},
			});
		} catch (error) {
			console.log(error);
		}
		handleSetFileUploading(false);
	};

	const handleReset = (e) => {
		e.target.value = null;
		setFileData('');
		setSelectedSheet('');
		setSheets({});
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<FileDragAndDrop
					readXLSXFile={readXLSXFile}
					handleReset={handleReset}
				/>
				{isIncorrect && (
					<p
						style={{
							fontSize: '17px !important',
							color: 'red',
							alignSelf: 'flex-start',
							marginBottom: '0px',
						}}
					>
						The dropped file does not appear to be an .XLSX file.
						Please try a different file.
					</p>
				)}
				<p>or</p>
				<Button
					variant="outlined"
					color="default"
					component="label"
					disabled={disabled}
					style={{
						height: '42px',
						width: '100%',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					Upload File
					<input
						type="file"
						accept=".xlsx"
						onChange={(e) => readXLSXFile(e.target.files[0])}
						onClick={(e) => {
							handleReset(e);
						}}
						hidden
					/>
				</Button>
			</div>

			{Object.keys(sheets).length > 1 && (
				<SheetsConfirmModal
					operatorData={operatorData}
					sheets={sheets}
					selectedSheet={selectedSheet}
					setSelectedSheet={handleSetSelectedSheet}
					displayModal={displaySheetsModal}
					handleDisplayModal={handleSetDisplaySheetsModal}
					workbook={tempWorkbook}
					readFile={readMultiXLSXFile}
				/>
			)}
		</>
	);
};

export default React.memo(FileUploader);

async function readFileData(file) {
	const promise = new Promise((res, rej) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = (e) => {
			res(e.target.result);
		};
		reader.onerror = (err) => {
			console.log(err);
			rej(err);
		};
	});
	return promise;
}

function deleteWorksheet(workbook, worksheetName) {
	const sidx = workbook.SheetNames.indexOf(worksheetName);
	if (sidx === -1) return;

	// remove from workbook
	workbook.SheetNames.splice(sidx, 1);
	delete workbook.Sheets[worksheetName];

	// update other structures
	if (workbook.Workbook) {
		if (workbook.Workbook.Views) workbook.Workbook.Views.splice(sidx, 1);
		if (workbook.Workbook.Names) {
			let names = workbook.Workbook.Names;
			for (let j = names.length - 1; j >= 0; --j) {
				if (names[j].Sheet === sidx) names = names.splice(j, 1);
				else if (names[j].Sheet > sidx) --names[j].Sheet;
			}
		}
	}
}
