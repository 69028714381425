import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

const LoadingMessage = ({ children }) => {
	return (
		<Button color="default" disabled={true}>
			<CircularProgress size={20} style={{ marginRight: '10px' }} />
			{children}
		</Button>
	);
};

export default LoadingMessage;
