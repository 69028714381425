import React, { useState, useEffect } from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DataTable from 'react-data-table-component';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export default function TableAccordion({
	label,
	data,
	includedHeaders = null,
}) {
	const [tableColumns, setTableColumns] = useState([]);
	const classes = useStyles();

	function dataToString(variable) {
		if (variable === undefined) return '';
		if (variable === null) return 'null';
		return variable.toString();
	}

	useEffect(() => {
		if (data.length === 0) {
			setTableColumns([]);
			return;
		}

		const columns = Object.keys(data[0]).reduce((acc, header) => {
			if (!includedHeaders || includedHeaders.includes(header)) {
				acc.push({
					name: header,
					selector: (row) => dataToString(row[header]),
					sortable: true,
					minWidth: '200px',
					maxWidth: '300px',
				});
			}
			return acc;
		}, []);

		setTableColumns(columns);
	}, [data, includedHeaders]);

	return (
		<div>
			<Accordion variant="outlined" className="accordion-borderless">
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.typographyStyle}>
						{label}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div style={{ maxWidth: '100%' }}>
						<DataTable
							noHeader={true}
							columns={tableColumns}
							data={data}
							dense={true}
							striped={true}
							highlightOnHover={true}
							noDataComponent={''}
							responsive={true}
							pagination={true}
							paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
						/>
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

const useStyles = makeStyles({
	typographyStyle: {
		color: '#0e4c8a',
	},
});
