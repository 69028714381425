import React from 'react';
import { TextField } from '@material-ui/core';

const DatePicker = ({ setSelectedDate, isoTime = false }) => {
	function setDate(selectedDate) {
		if (isoTime) {
			const timeZone = new Date().toString().match(/([-+][0-9]+)\s/)[1];
			const formattedSelectedDate = selectedDate + `T00:00:00${timeZone}`;
			setSelectedDate(formattedSelectedDate);
		} else {
			setSelectedDate(selectedDate);
		}
	}

	return (
		<div>
			<TextField
				variant="outlined"
				size="small"
				type="date"
				label="Select date"
				onChange={(e) => setDate(e.target.value)}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		</div>
	);
};

export default DatePicker;
