import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
	useAuthDispatch,
	logoutUser,
	useAuthState,
} from '../../context/auth/index';
import LoadingMessage from '../../components/LoadingMessage';
import JobsTable from './JobsTable';
import TableAlert from '../../components/Alerts/TableAlerts';
import { useSocket } from '../../context/SocketProvider';
import useIFramed from '../../hooks/useIFrame';

function JobsPage() {
	const dispatch = useAuthDispatch();
	const [loading, setLoading] = useState(false);
	const [reports, setReports] = useState([]);
	const [initialReports, setInitialReports] = useState(0);
	const [status, setStatus] = useState({ severity: '', code: 0 });
	const socket = useSocket();
	const { accountId } = useAuthState();
	const isIFramed = useIFramed();

	useEffect(() => {
		getSummaryReports();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (socket == null) return;
		socket.on(`${accountId}_job_create`, (socketReport) => {
			setReports([...reports, socketReport]);
			setInitialReports([...reports, socketReport]);
		});

		socket.on(`${accountId}_job_status`, (socketReport) => {
			const reportsModified = [...reports];
			const reportIndex = reportsModified.findIndex(
				(report) => report.jobId === socketReport.jobId
			);

			//Add to the table if job doesnt exist for some reason
			if (reportIndex === -1) {
				setReports([...reports, socketReport]);
				setInitialReports([...reports, socketReport]);
				return;
			}

			//Modify the job if it does exist
			reportsModified[reportIndex] = socketReport;
			setReports(reportsModified);
			setInitialReports(reportsModified);
		});

		return () => {
			socket.off(`${accountId}_job_create`);
			socket.off(`${accountId}_job_status`);
		};
	}, [socket, accountId, reports]);

	const handleSetReports = (reports) => {
		setReports(reports);
	};

	async function getSummaryReports() {
		setLoading(true);
		axios
			.get('jobs/read')
			.then((res) => {
				setLoading(false);
				setReports(res.data.data);
				setInitialReports(res.data.data);
				if (reports.length === 0)
					setStatus({ severity: 'warning', code: '' });
			})
			.catch((e) => {
				setLoading(false);
				setStatus({ severity: 'error', code: e.response.status });
				console.log(e);
				if (e.response.status === 401) {
					logoutUser(dispatch);
				}
			});
	}

	return (
		<div
			className={
				isIFramed
					? 'app__container-jobs-iframed'
					: 'app__container-jobs'
			}
		>
			<section>
				<p>
					View records of all Bulk Task Smasher jobs submitted in your
					Uberflip account.
				</p>
				<p>
					Click on any finished job (any job with the status{' '}
					<b>Completed, Warning, or Failed</b>), to view more details
					about it, as well as a complete log of all bulk actions
					performed.
				</p>
			</section>
			{loading ? (
				<LoadingMessage>Loading Jobs...</LoadingMessage>
			) : initialReports.length > 0 ? (
				<JobsTable
					tableData={reports}
					initialTableData={initialReports}
					handleSetTableData={handleSetReports}
				/>
			) : status.severity === 'warning' ? (
				<TableAlert status={status.severity} />
			) : (
				<TableAlert status={status.severity} code={status.code} />
			)}
		</div>
	);
}

export default JobsPage;
