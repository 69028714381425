import React, { useState, useEffect } from 'react';
import CellDataLimitModal from '../../Modals/CellDataLimitModal';
import workerEvent from '../../../hooks/workerEvent';

const EXCEL_CELL_CHAR_LIMIT = 32767;

export default function ExportToXLSX({
	operatorData = [],
	selectedHeaders,
	fileData = null,
	fileName,
	options,
	handleSetJobStatus = () => {},
}) {
	const [displayModal, setDisplayModal] = useState(false);
	const [trimExceededCells, setTrimExceededCells] = useState(false);
	const [eventData, setEventData] = useState(null);

	useEffect(() => {
		if (fileData) {
			generateXLSXWorkbook();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileData]);

	useEffect(() => {
		if (eventData === null) return;
		if (eventData.le?.length > 0) {
			handleSetJobStatus({
				loading: true,
				message: 'Pending user selection',
			});
			setDisplayModal(true);
		} else {
			// Export workbook to Excel
			downloadBlob(eventData.url, eventData.fn);
			setEventData(null);
			handleSetJobStatus({ loading: false, message: '' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventData]);

	const handleSetTrimExceededCells = (boolean) => {
		setTrimExceededCells(boolean);
	};

	const handleSetDisplayModal = (boolean) => {
		setDisplayModal(boolean);
	};

	async function generateXLSXWorkbook() {
		handleSetJobStatus({ loading: true, message: 'Exporting Data' });
		try {
			const data = await workerEvent('Generate XLSX', {
				operatorData,
				selectedHeaders,
				fileData,
				fileName,
				options,
				EXCEL_CELL_CHAR_LIMIT,
			});
			setEventData(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function generateTemplate() {
		// checking if XLSX file needs to be reformatted
		if (!trimExceededCells) {
			handleSetJobStatus({ loading: true, message: 'Exporting Data' });
			try {
				const data = await workerEvent('Trim XLSX Cells', {
					operatorData,
					fileName,
					xlsxWorkbook: eventData,
				});

				setEventData(data);
			} catch (error) {
				console.log(error);
			}
		} else {
			// Export workbook to Excel
			downloadBlob(eventData.url, eventData.fn);
			setEventData(null);
			handleSetJobStatus({ loading: false, message: '' });
		}
	}

	return (
		<>
			{displayModal && (
				<CellDataLimitModal
					displayModal={displayModal}
					handleDisplayModal={handleSetDisplayModal}
					advancedOption={trimExceededCells}
					totalExceededCells={eventData?.le?.length}
					handleSetAdvancedOption={handleSetTrimExceededCells}
					excelCellCharLimit={EXCEL_CELL_CHAR_LIMIT}
					handleSetJobStatus={handleSetJobStatus}
					generateTemplate={generateTemplate}
				/>
			)}
		</>
	);
}

const downloadBlob = (url, fn) => {
	const link = document.createElement('a');
	link.href = url;
	link.download = fn;
	link.click();
};
