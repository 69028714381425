import React, { useState, useRef } from 'react';
import {
	Grid,
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grow,
	Paper,
	Popper,
	MenuItem,
	MenuList,
} from '@material-ui/core';
import { ArrowDropDown, InsertDriveFile } from '@material-ui/icons';
import SocketDataExport from '../SocketDataExport/SocketDataExport';
import TipAlertModal from '../Modals/TipAlertModal';

const options = ['Populated Template', 'Blank Template'];

export default function SplitTemplateButton({
	operatorData,
	jobStatus,
	handleSetJobStatus,
	handleSetFileData,
}) {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Grid container direction="column" alignItems="center">
				<Grid item xs={12}>
					<ButtonGroup
						variant="contained"
						color="primary"
						ref={anchorRef}
						aria-label="split button"
					>
						{selectedIndex === 0 ? (
							<SocketDataExport
								operatorData={operatorData}
								handleSetFileData={handleSetFileData}
								jobStatus={jobStatus}
								handleSetJobStatus={handleSetJobStatus}
								splitButton={true}
								buttonText={'POPULATED TEMPLATE'}
								initializingText={`Initializing Analysis`}
								exportingText={`Populating ${operatorData.category}`}
							/>
						) : (
							<Button
								startIcon={<InsertDriveFile />}
								disabled={jobStatus.loading}
								onClick={() => {
									handleSetFileData([]);
								}}
							>
								BLANK TEMPLATE
							</Button>
						)}
						<Button
							color="primary"
							size="small"
							aria-controls={
								open ? 'split-button-menu' : undefined
							}
							aria-expanded={open ? 'true' : undefined}
							aria-label="select merge strategy"
							aria-haspopup="menu"
							disabled={jobStatus.loading}
							onClick={handleToggle}
						>
							<ArrowDropDown />
						</Button>
					</ButtonGroup>

					<Popper
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin:
										placement === 'bottom'
											? 'center top'
											: 'center bottom',
								}}
							>
								<Paper>
									<ClickAwayListener
										onClickAway={handleClose}
									>
										<MenuList id="split-button-menu">
											{options.map((option, index) => (
												<MenuItem
													key={option}
													selected={
														index === selectedIndex
													}
													onClick={(event) =>
														handleMenuItemClick(
															event,
															index
														)
													}
												>
													{option}
												</MenuItem>
											))}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Grid>
			</Grid>
			{selectedIndex === 0 && (
				<TipAlertModal title={'Populated Templates'}>
					<div style={{ marginBottom: '20px' }}>
						Choose the <b>Populated Template</b> option to download
						a template file that has been prefilled with existing
						data from your Hub.
					</div>
					<div>
						When using the Populated Template option, keep in mind
						that Bulk Task Smasher will not ignore unchanged lines
						when you reupload the template file to start the job:
						all lines in the template file will be processed (and
						the corresponding resource in your account will be
						updated). As a result, we recommend that you remove
						unchanged lines from the Populated Template file before
						reuploading it to Bulk Task Smasher.
					</div>
				</TipAlertModal>
			)}
		</div>
	);
}
