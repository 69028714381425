import React from 'react';
import {
	makeStyles,
	Button,
	Menu,
	MenuItem,
	Fade,
	Avatar,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { ExpandMore, PowerSettingsNew } from '@material-ui/icons';
import { useAuthState, useAuthDispatch, logoutUser } from '../../context/auth';

export default function AccountMenu() {
	const styles = useStyles();
	const dispatch = useAuthDispatch();
	const { accountId, firstName, lastName, avatarURL, version } =
		useAuthState();
	const shortName = useMediaQuery('(max-width:1000px)');
	const noName = useMediaQuery('(max-width:840px)');

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div style={{ marginLeft: 'auto' }}>
			<Button
				startIcon={
					avatarURL ? (
						<Avatar className={styles.avatar} src={avatarURL} />
					) : (
						<Avatar
							className={styles.avatarInitials}
							fontSize={'small'}
						>
							<Typography style={{ fontSize: '13px' }}>
								{firstName[0]}
								{lastName[0]}
							</Typography>
						</Avatar>
					)
				}
				endIcon={<ExpandMore />}
				onClick={handleClick}
			>
				{noName
					? ``
					: shortName
					? `${firstName[0]}. ${lastName}`
					: `${firstName} ${lastName}`}
			</Button>
			<Menu
				id="fade-menu"
				className={styles.menu}
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				<MenuItem className={styles.menuHeading} disabled>
					Account ID
				</MenuItem>
				<MenuItem className={styles.menuItem}>{accountId}</MenuItem>
				<MenuItem className={styles.menuHeading} disabled>
					User
				</MenuItem>
				<MenuItem className={styles.menuItem}>
					{firstName} {lastName}
				</MenuItem>
				<MenuItem className={styles.menuHeading} disabled>
					Version
				</MenuItem>
				<MenuItem className={styles.menuItem}>{version}</MenuItem>

				<hr className={styles.hr} />

				<MenuItem onClick={() => logoutUser(dispatch)}>
					<PowerSettingsNew
						fontSize={'small'}
						style={{ paddingRight: '10px' }}
					/>{' '}
					Sign Out
				</MenuItem>
			</Menu>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	menu: {
		'& .MuiPaper-root': {
			minWidth: '160px',
		},
	},
	hr: {
		border: '1px solid #e5e5e5',
	},
	menuHeading: {
		textTransform: 'uppercase',
		fontSize: '12px',
		lineHeight: '1.45455',
	},
	menuItem: {
		cursor: 'pointer',
		fontSize: '16px',
		fontWeight: '600',
		pointerEvents: 'none',
	},
	avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	avatarInitials: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		backgroundColor: '#8a0e54',
	},
}));
