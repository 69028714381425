import React, { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import {
	Button,
	Select,
	MenuItem,
	CircularProgress,
	FormControl,
	InputLabel,
	FormGroup,
} from '@material-ui/core';
import { useAuthDispatch, logoutUser } from '../../../../context/auth';
import DatePicker from '../../../../components/DatePicker';
import JobConfirmModal from '../../../../components/Modals/JobConfirmModal';
import JobAlerts from '../../../../components/Alerts/JobAlerts';
import dateFormat from 'dateformat';
import SocketDataExport from '../../../../components/SocketDataExport/SocketDataExport';
import TableAccordion from '../../../../components/TableAccordion';
import InfoAlert from '../../../../components/Alerts/InfoAlert';
import HubPicker from '../../../../components/HubPicker';
import StreamPicker from '../../../../components/StreamPicker';

const UpdatePastItems = ({ operatorData }) => {
	const dispatch = useAuthDispatch();
	const [fileData, setFileData] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [itemHidden, setItemHidden] = useState('');
	const [displayModal, setDisplayModal] = useState(false);
	const [jobStatus, setJobStatus] = useState({
		loading: false,
		state: null,
		code: null,
		message: null,
	});
	const [hubIds, setHubIds] = useState([]);
	const [streams, setStreams] = useState([]);

	useEffect(() => {
		if (jobStatus.state === 'success') {
			setFileData([]);
			setItemHidden('');
			setSelectedDate(null);
		}
	}, [jobStatus]);

	const handleSetFileData = (data) => {
		setFileData(data);
	};

	const handleDisplayModal = (boolean) => {
		setDisplayModal(boolean);
	};

	const [filteredData, affectedItems] = useMemo(() => {
		if (!fileData || !selectedDate) return [[], null];
		const filteredData = fileData.filter((item) => {
			const itemDate = dateFormat(item.created_at, 'isoDateTime');

			const streamIds = streams.map((stream) => stream.id);

			const filterByStream = streamIds.length > 0;
			const filterByHub = hubIds.length > 0;

			const streamHubFilter = filterByStream
				? streamIds.includes(item.stream_id)
				: filterByHub
				? hubIds.includes(item.hub_id)
				: true;

			return itemDate < selectedDate && streamHubFilter;
		});
		return [filteredData, filteredData.length];
	}, [fileData, selectedDate, hubIds, streams]);

	const handleSetSelectedDate = (selectedDate) => {
		setSelectedDate(selectedDate);
	};

	const handleSetJobStatus = (data) => {
		setJobStatus(data);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setJobStatus({
			loading: true,
			state: null,
			jobId: null,
			message: null,
		});

		const payloadData = filteredData.map((item) => {
			return {
				item_id: item.id,
				hidden: itemHidden,
			};
		});

		const email = sessionStorage.getItem('email');

		Axios.post(
			process.env.REACT_APP_USE_LOCAL_WORKER === 'false'
				? operatorData.endpoint
				: 'jobs/create',
			{
				email: email,
				fileData: payloadData,
				operatorData: operatorData,
			}
		)
			.then((res) => {
				setJobStatus({
					loading: false,
					state: 'success',
					jobId: res?.data?.data,
					message: res?.data?.message,
				});
			})
			.catch((e) => {
				console.log(e);
				setJobStatus({
					loading: false,
					state: 'error',
					jobId: null,
					code: e?.response?.status,
					message: e?.response?.data?.message,
				});
				if (e.response.status === 401) {
					logoutUser(dispatch);
				}
			});
	};

	return (
		<>
			{jobStatus.state !== 'success' && (
				<>
					<DatePicker
						setSelectedDate={handleSetSelectedDate}
						isoTime={true}
					/>
					<br />
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							gap: '20px',
						}}
					>
						<HubPicker setHubIds={setHubIds} />
						<StreamPicker setStreams={setStreams} hubIds={hubIds} />
					</div>
				</>
			)}
			{jobStatus.state === 'success' ? (
				<></>
			) : fileData.length === 0 ? (
				<SocketDataExport
					operatorData={operatorData}
					handleSetFileData={handleSetFileData}
					jobStatus={jobStatus}
					handleSetJobStatus={handleSetJobStatus}
					buttonText={`Find ${operatorData.category}`}
					initializingText={`Searching ${operatorData.category}`}
					downloadingText={`Extracting ${operatorData.category}`}
					disabled={!selectedDate}
				/>
			) : (
				<>
					{affectedItems === 0 && (
						<>
							<br />
							There are <b>0</b> items created before the selected
							date.
							<br />
							<br />
							<InfoAlert title="TIP">
								<div>
									Try adjusting the cutoff date using the date
									picker — the number of Items found will be
									adjusted automatically.
								</div>
							</InfoAlert>
						</>
					)}
					{affectedItems > 0 && (
						<div>
							<TableAccordion
								label={`Found ${affectedItems} Items (click to expand)`}
								data={filteredData}
								includedHeaders={[
									'id',
									'type',
									'title',
									'url',
									'hidden',
									'created_at',
								]}
							/>
							<FormGroup className="form-group">
								<FormControl
									size="small"
									variant="outlined"
									style={{ width: '300px' }}
								>
									<InputLabel htmlFor="outlined-category-native-simple">
										Set Status To
									</InputLabel>
									<Select
										onChange={(e) => {
											setItemHidden(e.target.value);
										}}
										label="Set Status To"
										inputProps={{
											name: 'status',
											id: 'outlined-category-native-simple',
										}}
										value={itemHidden}
										required
									>
										<MenuItem value={'True'}>Hide</MenuItem>
										<MenuItem value={'False'}>
											Show
										</MenuItem>
									</Select>
								</FormControl>
							</FormGroup>
						</div>
					)}
					<Button
						variant="contained"
						onClick={() => handleDisplayModal(true)}
						disabled={
							jobStatus.loading ||
							!(affectedItems > 0) ||
							itemHidden === ''
						}
						style={{
							marginTop: '2rem',
							marginBottom: '2rem',
							height: '42px',
						}}
						fullWidth
					>
						{jobStatus.loading ? (
							<>
								<CircularProgress
									size={30}
									style={{
										marginRight: '10px',
									}}
								/>{' '}
								SUBMITTING JOB...
							</>
						) : (
							'SUBMIT JOB'
						)}
					</Button>
					<JobConfirmModal
						operatorData={operatorData}
						displayModal={displayModal}
						fileLength={affectedItems}
						itemHidden={itemHidden}
						handleDisplayModal={handleDisplayModal}
						handleJobSubmit={handleSubmit}
					/>
				</>
			)}

			{jobStatus.state !== null && (
				<JobAlerts
					jobStatus={jobStatus}
					operatorData={operatorData}
					fileLength={affectedItems}
				/>
			)}
		</>
	);
};

export default UpdatePastItems;
