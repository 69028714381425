import React from 'react';
import TemplateGenerator from '../../../../components/TemplateGenerator/TemplateGenerator';
import JobUploader from '../../../../components/JobUploader/JobUploader';
import TabControl from '../../../../components/TabControl/TabControl';
import TipAlertModal from '../../../../components/Modals/TipAlertModal';

export default function TsvOperator({ operatorData }) {
	return (
		<>
			<TipAlertModal
				buttonText="Planning to run a large job?"
				title="Perform a limited test run before running larger
								jobs"
			>
				<div style={{ marginBottom: '20px' }}>
					If you’re planning to run a job that affects a large number
					of resources (Items, Streams, Tags, etc.), we recommend
					performing a “test run” on a smaller subset of those
					resources first.
				</div>
				<div>
					Simply split the first 10-15 lines of your job template file
					into a separate file, then upload and run this file first.
					Check on the results in your account: if everything looks
					good, you can proceed with the rest of the job.
				</div>
			</TipAlertModal>

			<TabControl>
				<TemplateGenerator operatorData={operatorData} />
				<JobUploader operatorData={operatorData} />
			</TabControl>
		</>
	);
}
