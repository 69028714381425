import React, { useState } from 'react';
import {
	makeStyles,
	Modal,
	Fade,
	Button,
	Divider,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core/';
import { Warning, ExpandMore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import CancelDownloadModal from './CancelDownloadModal';

const DataLimitModal = ({
	totalExceededCells,
	displayModal,
	handleDisplayModal,
	advancedOption,
	handleSetAdvancedOption,
	generateTemplate,
	excelCellCharLimit,
	handleSetJobStatus,
}) => {
	const styles = useStyles();
	const [displayCancelledModal, setDisplayCancelledModal] = useState(false);

	const handleDisplayCancelDownload = (boolean) => {
		setDisplayCancelledModal(boolean);
	};

	return (
		<>
			<Modal
				closeAfterTransition
				className={styles.modal}
				open={displayModal}
			>
				<Fade in={displayModal}>
					<div className={styles.header}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Warning
								style={{
									margin: '5px 10px 5px 20px',
									color: '#a94442',
								}}
							/>

							<h3 id="transition-modal-title">
								Data exceeds Excel character limit
							</h3>
						</div>

						<div className={styles.body}>
							<div>
								<Alert
									severity="error"
									variant="filled"
									style={{
										color: '#a94442',
										backgroundColor: '#ebccd1',
										fontSize: '13px',
										border: 'solid 1px #ebccd1',
									}}
									icon={false}
								>
									<Typography>
										Opening this file in Microsoft Excel
										would cause the contents of some cells
										to be cut off because they exceed
										Excel's character limit.
									</Typography>
								</Alert>

								<div style={{ padding: '10px 15px 10px 15px' }}>
									<Typography>
										This file contains{' '}
										{totalExceededCells.toLocaleString()}{' '}
										{totalExceededCells === 1
											? 'cell'
											: 'cells'}{' '}
										that exceed Microsoft Excel’s limit of{' '}
										{excelCellCharLimit.toLocaleString()}{' '}
										characters per cell.
									</Typography>
									<br />
									<Typography>
										To avoid possible data loss, Bulk Task
										Smasher has removed the contents of the
										over-limit cells from this file.
									</Typography>
									<br />
									<a
										className="excel-documentation-link"
										href={
											'https://help.uberflip.com/hc/en-us/articles/4411353508109'
										}
										target={'_blank'}
										rel="noreferrer"
									>
										<Typography
											style={{
												color: '#ce0058',
												fontStyle: 'italic',
											}}
										>
											Learn more about files that exceed
											the Excel character limit
										</Typography>
									</a>
									<br />

									<Accordion
										className="accordion-borderless"
										variant={'outlined'}
									>
										<AccordionSummary
											expandIcon={<ExpandMore />}
										>
											<Typography>
												Advanced options
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															value={
																advancedOption
															}
															onChange={(e) => {
																handleSetAdvancedOption(
																	e.target
																		.checked
																);
															}}
														/>
													}
													label="I understand the risks, download this file with the over-limit cells included."
												/>
											</FormGroup>
										</AccordionDetails>
									</Accordion>
								</div>
							</div>
						</div>
						<Divider />
						<div className={styles.footer}>
							<Button
								variant="outlined"
								className={styles.cancelButton}
								onClick={() => {
									handleDisplayCancelDownload(true);
									handleSetJobStatus({
										loading: true,
										message: 'Pending User Selection',
									});
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								size="medium"
								onClick={() => {
									generateTemplate();
									handleDisplayModal(false);
									handleSetAdvancedOption(false);
								}}
							>
								{advancedOption
									? 'Get Non Excel Compatible File'
									: 'Get File'}
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>

			{displayCancelledModal && (
				<CancelDownloadModal
					handleDisplayModal={handleDisplayModal}
					displayCancelledModal={displayCancelledModal}
					handleDisplayCancelDownload={handleDisplayCancelDownload}
					handleSetJobStatus={handleSetJobStatus}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '15%',
	},
	header: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		wordWrap: 'break-word',
		borderRadius: '5px !important',
		maxWidth: '700px',
		padding: '15px',
		lineHeight: '1.5',
	},
	body: {
		padding: '0px 20px 10px 20px',
		fontSize: '12px',
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '15px 0px 0px 0px',
	},
	cancelButton: {
		textTransform: 'none',
		marginRight: '10px',
	},
}));

export default DataLimitModal;
