import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Modal,
	Backdrop,
	Fade,
	Button,
	Divider,
	TextField,
	FormControl,
	FormHelperText,
	Typography,
} from '@material-ui/core/';
import { Warning } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const JobConfirmModal = ({
	operatorData,
	fileLength,
	displayModal,
	handleDisplayModal,
	handleJobSubmit,
}) => {
	const styles = useStyles();
	const [email, setEmail] = useState(
		sessionStorage.getItem('email') ? sessionStorage.getItem('email') : ''
	);
	const [emailValidated, setEmailValidated] = useState(true);
	const [jobConfirmation, setJobConfirmation] = useState('');
	const [confirmed, setConfirmed] = useState(false);

	useEffect(() => {
		handleJobConfirmation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email, jobConfirmation]);

	const validateEmail = (email, setError) => {
		var emailPattern = new RegExp(
			/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
		);
		if (!emailPattern.test(email) && setError) {
			setEmailValidated(false);
		} else {
			setEmailValidated(true);
			sessionStorage.setItem('email', email);
		}
	};

	const handleJobConfirmation = () => {
		if (jobConfirmation === 'CONFIRM') setConfirmed(true);
		else setConfirmed(false);
	};

	const handleClose = () => {
		handleDisplayModal(false);
	};

	return (
		<>
			<Modal
				closeAfterTransition
				className={styles.modal}
				open={displayModal}
				onClose={handleClose}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={displayModal}>
					<div className={styles.header}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Warning
								style={{
									margin: '5px 10px 5px 20px',
									color: '#a94442',
								}}
							/>
							<h3>
								Running this job with the uploaded file will
								affect{' '}
								<b>
									{fileLength}{' '}
									{fileLength === 1
										? operatorData.category.slice(0, -1)
										: operatorData.category}
								</b>
								.
							</h3>
						</div>

						<div className={styles.body}>
							<Alert
								severity="info"
								variant="filled"
								icon={false}
								style={{
									color: '#a94442',
									backgroundColor: '#ebccd1',
									fontSize: '13px',
									border: 'solid 1px #ebccd1',
								}}
							>
								<Typography>
									When you click the <b>Confirm Job</b>{' '}
									button, this job will be executed.{' '}
									<b>
										You will not be able to stop or undo
										this action.
									</b>
								</Typography>
							</Alert>
							<div style={{ paddingTop: '12px' }}>
								<Typography>
									When the <b>{operatorData.name} </b> job
									finishes, an email notification will be sent
									to:
								</Typography>
								<FormControl className={styles.emailInput}>
									<TextField
										id="input-field-email"
										type="email"
										label="Recipient Email Address"
										variant="outlined"
										size="small"
										value={email}
										fullWidth
										onChange={(e) => {
											setEmail(e.target.value);
											validateEmail(
												e.target.value,
												false
											);
										}}
										onBlur={(e) => {
											if (e.target.value.length > 0) {
												validateEmail(
													e.target.value,
													true
												);
											}
										}}
										autoFocus
										helpertext={
											!emailValidated ? (
												'Please enter a valid email address.'
											) : (
												<></>
											)
										}
										error={!emailValidated}
									/>
									<FormHelperText
										id="input-field-email"
										className={styles.errorText}
									>
										{!emailValidated &&
											'Please enter a valid email address.'}
									</FormHelperText>
								</FormControl>
							</div>

							<div>
								<Typography>
									Type the word CONFIRM below to proceed:
								</Typography>

								<TextField
									id="input-field-confirmation"
									type="text"
									variant="outlined"
									size="small"
									className={styles.input}
									label="CONFIRM"
									value={jobConfirmation}
									fullWidth
									onChange={(e) =>
										setJobConfirmation(e.target.value)
									}
									required
								/>
							</div>

							<Divider style={{ marginTop: '15px' }} />
							<div className={styles.footer}>
								<Button
									variant="outlined"
									className={styles.cancelButton}
									onClick={handleClose}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									size="medium"
									disabled={!confirmed || !emailValidated}
									onClick={(e) => {
										handleJobSubmit(e);
										handleClose();
									}}
								>
									Confirm Job
								</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '15%',
	},
	header: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		wordWrap: 'break-word',
		borderRadius: '5px !important',
		maxWidth: '700px',
		padding: '15px',
		lineHeight: '1.5',
	},
	body: {
		padding: '0px 20px 20px 20px',
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '15px 0px 0px 0px',
	},
	cancelButton: {
		textTransform: 'none',
		marginRight: '10px',
	},
	jobDetails: {
		paddingLeft: '4px',
		fontSize: '16px !important',
	},
	emailInput: {
		display: 'flex',
		margin: '10px 0px 15px 0px',
	},
	input: {
		display: 'flex',
		marginTop: '10px',
		'& .MuiFormLabel-asterisk': {
			display: 'none',
		},
	},
	errorText: {
		color: '#f44336',
		alignItems: 'left',
	},
}));

export default JobConfirmModal;
