import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	FormControl,
	InputLabel,
	Input,
	InputAdornment,
	Button,
	IconButton,
	CircularProgress,
	FormHelperText,
	Typography,
	Link,
	Avatar,
	Paper,
	useMediaQuery,
} from '@material-ui/core';
import {
	loginUser,
	validateUser,
	useAuthState,
	useAuthDispatch,
} from '../../context/auth';
import { Visibility, VisibilityOff, OpenInNew } from '@material-ui/icons/';
import logo from '../../images/bts_logo.svg';

const { REACT_APP_BS_PASSWORD, REACT_APP_API_KEY, REACT_APP_API_SECRET } =
	process.env;

export default function LoginForm({ handleAuthSuccess }) {
	const styles = useStyles();
	const dispatch = useAuthDispatch();
	const {
		loginLoading,
		statusLoading,
		errorMessage,
		errorValidationPassphrase,
		errorValidationRESTCreds,
	} = useAuthState();
	const mobile = useMediaQuery('(max-width:580px)');

	const [passphrase, setPassphrase] = useState(REACT_APP_BS_PASSWORD);
	const [key, setKey] = useState(REACT_APP_API_KEY);
	const [secret, setSecret] = useState(REACT_APP_API_SECRET);
	const [showPassphrase, setShowPassphrase] = useState(false);
	const [showKey, setShowKey] = useState(false);
	const [showSecret, setShowSecret] = useState(false);
	const [showPassphraseEye, setShowPassphraseEye] = useState(false);
	const [showKeyEye, setShowKeyEye] = useState(false);
	const [showSecretEye, setShowSecretEye] = useState(false);

	const handleTogglePassword = (password, stateFunction) => {
		stateFunction(!password);
	};

	useEffect(() => {
		validateUser(dispatch)
			.then((data) => {
				if (data?.authenticated) handleAuthSuccess();
			})
			.catch((e) => {
				console.log('Validation Error', e);
			});
	}, [dispatch, handleAuthSuccess]);

	const handleLogin = async (e) => {
		e.preventDefault();

		try {
			let data = await loginUser(dispatch, {
				passphrase,
				authCreds: { APIKey: key, APISecret: secret },
			});
			if (data?.authenticated) handleAuthSuccess();
		} catch (e) {
			console.log('Login Error', e);
		}
	};

	if (statusLoading) return <div></div>;

	return (
		<>
			<form className={mobile ? styles.formMobile : styles.form}>
				<Avatar className={styles.avatar} alt="logo" src={logo} />

				<Typography className={styles.title}>
					{mobile
						? 'Bulk Task Smasher'
						: 'Sign in to Bulk Task Smasher'}
				</Typography>
				<Paper className={styles.form}>
					<FormControl>
						<InputLabel htmlFor="input-field-passphrase">
							Passphrase
						</InputLabel>
						<Input
							id="input-field-passphrase"
							className={styles.input}
							type={showPassphrase ? 'text' : 'password'}
							value={passphrase}
							onChange={(e) => setPassphrase(e.target.value)}
							disabled={loginLoading}
							error={errorValidationPassphrase}
							onMouseEnter={() => setShowPassphraseEye(true)}
							onMouseLeave={() => setShowPassphraseEye(false)}
							required
							endAdornment={
								showPassphraseEye ? (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												handleTogglePassword(
													showPassphrase,
													setShowPassphrase
												);
											}}
										>
											{showPassphrase ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								) : (
									''
								)
							}
						/>
						<FormHelperText
							id="input-field-passphrase"
							className={styles.error}
						>
							{errorValidationPassphrase ? errorMessage : <></>}
						</FormHelperText>
					</FormControl>
					<FormControl>
						<InputLabel htmlFor="input-field-key">
							API Key
						</InputLabel>
						<Input
							id="input-field-key"
							className={styles.input}
							type={showKey ? 'text' : 'password'}
							label="API Key"
							value={key}
							onChange={(e) => setKey(e.target.value)}
							disabled={loginLoading}
							error={errorValidationRESTCreds}
							onMouseEnter={() => setShowKeyEye(true)}
							onMouseLeave={() => setShowKeyEye(false)}
							required
							endAdornment={
								showKeyEye && (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												handleTogglePassword(
													showKey,
													setShowKey
												);
											}}
										>
											{showKey ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								)
							}
						/>
					</FormControl>
					<FormControl>
						<InputLabel htmlFor="input-field-Secret">
							API Secret
						</InputLabel>
						<Input
							id="input-field-Secret"
							className={styles.input}
							type={showSecret ? 'text' : 'password'}
							label="API Secret"
							value={secret}
							onChange={(e) => setSecret(e.target.value)}
							disabled={loginLoading}
							error={errorValidationRESTCreds}
							onMouseEnter={() => setShowSecretEye(true)}
							onMouseLeave={() => setShowSecretEye(false)}
							required
							endAdornment={
								showSecretEye && (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												handleTogglePassword(
													showSecret,
													setShowSecret
												);
											}}
										>
											{showSecret ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								)
							}
						/>
						<FormHelperText className={styles.error}>
							{errorValidationRESTCreds ? errorMessage : <></>}
						</FormHelperText>
					</FormControl>
					<Button
						type="submit"
						variant="contained"
						disabled={loginLoading}
						onClick={handleLogin}
						style={{ height: '42px' }}
					>
						{loginLoading ? (
							<>
								<CircularProgress
									size={30}
									className={styles.progress}
									style={{
										marginRight: '10px',
									}}
								/>{' '}
								Signing in...
							</>
						) : (
							'Sign In'
						)}
					</Button>
					<Typography
						className={styles.credentials}
						variant="body2"
						color="textSecondary"
					>
						<Link
							color="inherit"
							href="https://app.uberflip.com/account/api_v2"
							target="_blank"
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							View REST API Credentials{' '}
							<OpenInNew
								fontSize="small"
								style={{ paddingLeft: '3px' }}
							/>
						</Link>
					</Typography>
				</Paper>
			</form>
		</>
	);
}
const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '45px',

		'& .MuiFormControl-root': {
			margin: theme.spacing(1),
			width: '400px',
		},
		'& .MuiButtonBase-root': {
			margin: theme.spacing(2),
		},
		'& .MuiButton-contained': {
			width: '400px',
		},
	},
	formMobile: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '45px 0px 0px 0px',
		marginLeft: 'auto',

		'& .MuiFormControl-root': {
			margin: theme.spacing(1),
			width: '300px',
		},
		'& .MuiButtonBase-root': {
			margin: theme.spacing(2),
		},
		'& .MuiButton-contained': {
			width: '300px',
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	input: {
		'& .MuiFormLabel-root.Mui-error': {
			color: '#f44336',
		},
	},
	progress: {
		'& .MuiCircularProgress-colorPrimary': {
			color: '#0a8b43 !important',
			marginRight: '15px !important',
		},
	},
	error: {
		color: '#f44336',
	},
	credentials: {
		paddingTop: '8px',
	},
	title: {
		paddingTop: '30px',
		paddingBottom: '30px',
		fontSize: '18px',
		textTransform: 'uppercase',
		letterSpacing: '0.1em',
	},
	avatar: {
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
}));
