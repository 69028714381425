import React from 'react';
import '@fontsource/roboto';
import CategorySelector from './CategorySelector';
import OperationSelector from './OperationSelector';
import OperatorIndex from './Operators/OperatorIndex';
import { MenuItem, Paper } from '@material-ui/core';
import useSessionStorage from '../../hooks/useSessionStorage';
import OperatorHeader from './Operators/OperatorHeader';
import useIFramed from '../../hooks/useIFrame';

function LandingPage() {
	const [operationJSONArray, setOperationJSONArray] = useSessionStorage(
		'operationJSON',
		[]
	);
	const [operator, setOperator] = useSessionStorage('operator', {});
	const [category, setCategory] = useSessionStorage('category', '');
	const OperatorToRender = operator ? OperatorIndex[operator.name] : {};
	const isIFramed = useIFramed();

	const handleSetOperationJSONArray = async (jsonArray) => {
		setOperationJSONArray(jsonArray);
		setOperator({});
	};

	//fix the on category change
	const handleSetOperator = async (operator) => {
		setOperator({});
		setOperator(operator);
	};

	return (
		<div
			className={
				isIFramed
					? 'app__container-body-iframed '
					: 'app__container-body'
			}
		>
			<section>
				<p>
					Easily make changes to your Uberflip account in bulk by
					automating repetitive actions.
				</p>
			</section>
			<section style={{ marginTop: '3rem' }}>
				<CategorySelector
					category={category}
					setCategory={setCategory}
					operationJSONArray={operationJSONArray}
					setOperationJSONArray={handleSetOperationJSONArray}
				>
					<MenuItem value={'Items'}>Items</MenuItem>
					<MenuItem value={'CTAs'}>CTAs</MenuItem>
					<MenuItem value={'Tags'}>Tags</MenuItem>
					<MenuItem value={'Streams'}>Streams</MenuItem>
					{!isIFramed && (
						<MenuItem value={'Filters'}>Smart Filters</MenuItem>
					)}
					{!isIFramed && (
						<MenuItem value={'Redirects'}>Redirect Rules</MenuItem>
					)}
					<MenuItem value={'Users'}>Users</MenuItem>
					<MenuItem value={'PDFs'}>Flipbooks/PDFs</MenuItem>
				</CategorySelector>

				{operationJSONArray?.length > 0 && (
					<OperationSelector
						category={category}
						operators={operationJSONArray}
						operator={operator}
						setOperator={handleSetOperator}
					/>
				)}
			</section>
			{OperatorToRender && (
				<section>
					<Paper style={{ padding: '1rem 2rem' }} variant="outlined">
						<OperatorHeader
							name={operator.name}
							description={operator.description}
						/>
						<OperatorToRender operatorData={operator} />
					</Paper>
				</section>
			)}
		</div>
	);
}

export default LandingPage;
