import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core/';
import { useAuthState } from '../../../../context/auth';
import SocketDataExport from '../../../../components/SocketDataExport/SocketDataExport';
import JobAlerts from '../../../../components/Alerts/JobAlerts';
import ExportToXLSX from '../../../../components/TemplateExporter/XLSX/ExportToXLSX';
import InfoAlert from '../../../../components/Alerts/InfoAlert';
import HubPicker from '../../../../components/HubPicker';
import StreamPicker from '../../../../components/StreamPicker';
import CtaPicker from '../../../../components/CtaPicker';
import FlipbookPicker from '../../../../components/FlipbookPicker';

const hubFilterableOperatorCategories = [
	'Items',
	'Items Custom Code',
	'Streams',
	'Filters',
	'CTAs',
	'Redirects',
];
const streamFilterableOperatorCategories = ['Items', 'Items Custom Code'];

const ctaFilterableOperatorCategories = ['CTA Placements'];

const flipbooksOperatorCategories = ['PDFs'];

export default function ReadOperator({ operatorData }) {
	const { hubs } = useAuthState();
	const [fileData, setFileData] = useState(null);
	const [jobStatus, setJobStatus] = useState({
		loading: false,
		state: null,
		code: null,
		message: null,
	});
	const [runHybrid, setRunHybrid] = useState([]);
	const [hubIds, setHubIds] = useState(hubs.map((hub) => hub.id));
	const [streams, setStreams] = useState([]);
	const [ctas, setCtas] = useState([]);
	const [flipbookFolders, setFlipbookFolders] = useState([]);

	const handleSetRunHybrid = (clickedHybrid) => {
		if (!clickedHybrid.checked) {
			const hybridCopy = [...runHybrid];
			const index = hybridCopy.indexOf(clickedHybrid.value);
			hybridCopy.splice(index, 1);
			setRunHybrid(hybridCopy);
		} else {
			const hybridCopy = [...runHybrid, clickedHybrid.value];
			setRunHybrid(hybridCopy);
		}
	};

	const handleSetFileData = (data) => {
		setFileData(data);
	};

	const handleSetJobStatus = (data) => {
		setJobStatus(data);
	};

	const showFilters = hubFilterableOperatorCategories.includes(
		operatorData.category
	);

	const showStreamFilter = streamFilterableOperatorCategories.includes(
		operatorData.category
	);

	const showCtaFilter = ctaFilterableOperatorCategories.includes(
		operatorData.category
	);

	const showFlipbookFilter = flipbooksOperatorCategories.includes(
		operatorData.category
	);

	return (
		<>
			{showFilters && (
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: '20px',
					}}
				>
					<HubPicker setHubIds={setHubIds} />
					{showStreamFilter && (
						<StreamPicker setStreams={setStreams} hubIds={hubIds} />
					)}
				</div>
			)}
			{showCtaFilter && (
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: '20px',
					}}
				>
					<CtaPicker setCtas={setCtas} hubIds={hubIds} />
				</div>
			)}
			{showFlipbookFilter && (
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: '20px',
					}}
				>
					<FlipbookPicker setFlipbookFolders={setFlipbookFolders} />
				</div>
			)}
			{operatorData?.hybrid?.length > 0 && (
				<>
					<br />
					<Typography>
						You can also choose to include the following optional
						data in your export:
					</Typography>
					{operatorData?.hybrid?.length > 0 && (
						<>
							{operatorData?.hybrid?.map((operator) => {
								return (
									<div key={operator.name}>
										<FormControlLabel
											control={
												<Checkbox
													value={operator.name}
													onChange={(e) => {
														handleSetRunHybrid(
															e.target
														);
													}}
													color="primary"
												/>
											}
											label={operator.message}
										/>
										<br />
									</div>
								);
							})}
							<div>
								<br />
								<InfoAlert title="NOTE">
									<div>
										Selecting any of these options will, in
										most cases, significantly increase the
										time needed to complete the export.
									</div>
								</InfoAlert>
							</div>
						</>
					)}
					<br />
				</>
			)}
			<SocketDataExport
				operatorData={operatorData}
				handleSetFileData={handleSetFileData}
				jobStatus={jobStatus}
				handleSetJobStatus={handleSetJobStatus}
				runHybrid={runHybrid}
				hubIds={hubIds}
				streamIds={streams.map((stream) => stream.id)}
				ctaIdsName={ctas.map((cta) => [cta.id, cta.name])}
				flipbookId={flipbookFolders.map((fl) => fl.id)}
			/>

			{fileData && (
				<ExportToXLSX
					operatorData={operatorData}
					fileData={fileData}
					options={{
						flattenData: true,
					}}
					handleSetJobStatus={handleSetJobStatus}
				/>
			)}

			{jobStatus.state === 'read error' && (
				<JobAlerts jobStatus={jobStatus} operatorData={operatorData} />
			)}
		</>
	);
}
