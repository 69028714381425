import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function JobAlerts({ jobStatus, operatorData, fileLength = 0 }) {
	const styles = useStyles();
	const [jobCompletionETA, setJobCompletionETA] = useState(0);
	const email = sessionStorage.getItem('email');

	useEffect(() => {
		if (fileLength) estimateJobCompletionETA(fileLength);
	}, [fileLength]);

	const estimateJobCompletionETA = (fileLength) => {
		const estimatedTasksPerMinute = 800;
		const eta = Math.ceil(fileLength / estimatedTasksPerMinute);
		setJobCompletionETA(eta);
	};

	if (jobStatus.state === 'success') {
		return (
			<Alert severity="success" variant="outlined">
				<AlertTitle>Job Submitted to Queue</AlertTitle>
				<div className={styles.body}>
					Your job <b>{jobStatus.jobId} </b>has been submitted to the
					queue. This job is estimated to take{' '}
					<b>
						{jobCompletionETA}{' '}
						{jobCompletionETA === 1 ? 'minute' : 'minutes'}
					</b>{' '}
					to complete.
				</div>
				{email && (
					<div className={styles.body}>
						A detailed job summary will be sent to <b>{email}</b>{' '}
						once the job finishes.
					</div>
				)}
				<div className={styles.body}>
					<NavLink to="/jobs">Click here </NavLink>
					to view the status of your job.
				</div>
			</Alert>
		);
	} else if (jobStatus.state === 'error') {
		return (
			<Alert
				style={{ marginTop: '32px' }}
				severity="error"
				variant="outlined"
			>
				<AlertTitle>[{jobStatus.code}] Job Not Submitted</AlertTitle>

				<div>
					An error occurred submitting job to the queue. Please try
					again.
				</div>
			</Alert>
		);
	} else if (jobStatus.state === 'info') {
		return (
			<Alert
				style={{ marginTop: '20px' }}
				severity="info"
				variant="outlined"
			>
				<AlertTitle>
					TIP: Perform a limited test run before running larger jobs
				</AlertTitle>

				<div className={styles.body}>
					If you’re planning to run a job that affects a large number
					of resources (Items, Streams, Tags, etc.), we recommend
					performing a “test run” on a smaller subset of those
					resources first.
				</div>
				<div className={styles.body}>
					Simply split the first 10-15 lines of your job template file
					into a separate file, then upload and run this file first.
					Check on the results in your account: if everything looks
					good, you can proceed with the rest of the job.
				</div>
			</Alert>
		);
	} else if (jobStatus.state === 'read error') {
		return (
			<Alert
				style={{ marginTop: '32px' }}
				severity="error"
				variant="outlined"
			>
				<AlertTitle>
					{!jobStatus?.code && !jobStatus?.message
						? 'Read Error'
						: !jobStatus?.code
						? `${jobStatus?.message}`
						: !jobStatus?.message
						? `[${jobStatus?.code}] - Read Error`
						: `[${jobStatus?.code}] - ${jobStatus?.message}`}
				</AlertTitle>

				<div className={styles.body}>
					An error occurred reading {operatorData.category}. Please
					try again.
				</div>
				<div className={styles.body}>
					If this persists, please contact Uberflip Support.
				</div>
			</Alert>
		);
	}
}

const useStyles = makeStyles((theme) => ({
	body: {
		color: 'rgba(0, 0, 0, 0.87)',
		marginTop: '10px',
	},
}));
