import React, { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import { Button, CircularProgress } from '@material-ui/core';
import { useAuthDispatch, logoutUser } from '../../../../context/auth';
import JobConfirmModal from '../../../../components/Modals/JobConfirmModal';
import JobAlerts from '../../../../components/Alerts/JobAlerts';
import SocketDataExport from '../../../../components/SocketDataExport/SocketDataExport';
import TableAccordion from '../../../../components/TableAccordion';
import HubPicker from '../../../../components/HubPicker';
import StreamPicker from '../../../../components/StreamPicker';
import InfoAlert from '../../../../components/Alerts/InfoAlert';

const DeleteSecondaryUrls = ({ operatorData }) => {
	const dispatch = useAuthDispatch();
	const [fileData, setFileData] = useState([]);
	const [displayModal, setDisplayModal] = useState(false);
	const [jobStatus, setJobStatus] = useState({
		loading: false,
		state: null,
		code: null,
		message: null,
	});
	const [hubIds, setHubIds] = useState([]);
	const [streams, setStreams] = useState([]);
	const streamIds = streams.map((stream) => stream.id);

	const noHubs = hubIds.length === 0;
	const noStreams = streams.length === 0;

	useEffect(() => {
		if (jobStatus.state === 'success') {
			setFileData([]);
		}
	}, [jobStatus]);

	useEffect(() => {
		setFileData([]);
	}, [hubIds, streams]);

	const handleSetFileData = (data) => {
		setFileData(data);
	};

	const handleDisplayModal = (boolean) => {
		setDisplayModal(boolean);
	};

	const [filteredData, affectedItems] = useMemo(() => {
		if (!fileData) return [[], null];

		const mutatedData = fileData.reduce((acc, item) => {
			const itemCopy = {
				...item,
				primary_url_slug: item.url_slug,
			};

			if (itemCopy.secondary_url_slugs !== '') acc.push(itemCopy);

			return acc;
		}, []);

		return [mutatedData, mutatedData.length];
	}, [fileData]);

	const handleSetJobStatus = (data) => {
		setJobStatus(data);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setJobStatus({
			loading: true,
			state: null,
			jobId: null,
			message: null,
		});

		const payloadData = filteredData.map((item) => {
			return {
				item_id: item.id,
				remove_existing_secondary_urls: true,
			};
		});

		const email = sessionStorage.getItem('email');

		Axios.post(
			process.env.REACT_APP_USE_LOCAL_WORKER === 'false'
				? operatorData.endpoint
				: 'jobs/create',
			{
				email: email,
				fileData: payloadData,
				operatorData: operatorData,
			}
		)
			.then((res) => {
				setJobStatus({
					loading: false,
					state: 'success',
					jobId: res?.data?.data,
					message: res?.data?.message,
				});
			})
			.catch((e) => {
				console.log(e);
				setJobStatus({
					loading: false,
					state: 'error',
					jobId: null,
					code: e?.response?.status,
					message: e?.response?.data?.message,
				});
				if (e.response.status === 401) {
					logoutUser(dispatch);
				}
			});
	};

	return (
		<>
			{jobStatus.state !== 'success' && (
				<>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							gap: '20px',
						}}
					>
						<HubPicker setHubIds={setHubIds} />
						<StreamPicker setStreams={setStreams} hubIds={hubIds} />
					</div>
					{(noHubs || noStreams) && (
						<InfoAlert title="NOTE">
							<div>
								Not selecting any Hubs or Streams, in most
								cases, will significantly increase the time
								needed to complete the data retrieval.
							</div>
						</InfoAlert>
					)}
				</>
			)}
			{jobStatus.state === 'success' ? (
				<></>
			) : fileData.length === 0 ? (
				<SocketDataExport
					operatorData={operatorData}
					handleSetFileData={handleSetFileData}
					jobStatus={jobStatus}
					handleSetJobStatus={handleSetJobStatus}
					buttonText={`Find Secondary URLs`}
					initializingText={`Searching ${operatorData.category}`}
					downloadingText={`Extracting ${operatorData.category}`}
					hubIds={hubIds}
					streamIds={streamIds}
					runHybrid={['Item Secondary URLs']}
				/>
			) : (
				<>
					{affectedItems === 0 && (
						<>
							<br />
							There are <b>0</b> items within the selected Hubs or
							Streams.
						</>
					)}
					{affectedItems > 0 && (
						<div>
							<TableAccordion
								label={`Found ${affectedItems} Items (click to expand)`}
								data={filteredData}
								includedHeaders={[
									'id',
									'title',
									'primary_url_slug',
									'secondary_url_slugs',
								]}
							/>
						</div>
					)}
					<Button
						variant="contained"
						onClick={() => handleDisplayModal(true)}
						disabled={jobStatus.loading || !(affectedItems > 0)}
						style={{
							marginTop: '2rem',
							marginBottom: '2rem',
							height: '42px',
						}}
						fullWidth
					>
						{jobStatus.loading ? (
							<>
								<CircularProgress
									size={30}
									style={{
										marginRight: '10px',
									}}
								/>{' '}
								SUBMITTING JOB...
							</>
						) : (
							'SUBMIT JOB'
						)}
					</Button>
					<JobConfirmModal
						operatorData={operatorData}
						displayModal={displayModal}
						fileLength={affectedItems}
						handleDisplayModal={handleDisplayModal}
						handleJobSubmit={handleSubmit}
					/>
				</>
			)}

			{jobStatus.state !== null && (
				<JobAlerts
					jobStatus={jobStatus}
					operatorData={operatorData}
					fileLength={affectedItems}
				/>
			)}
		</>
	);
};

export default DeleteSecondaryUrls;
