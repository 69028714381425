import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

function ReadProgress({ message, progress }) {
	const showSpinnerProgress = progress !== 0 && progress !== 100;

	return (
		<>
			<Box
				position="relative"
				display="inline-flex"
				style={{
					marginRight: '10px',
				}}
			>
				<CircularProgress
					color="primary"
					size={30}
					variant={
						showSpinnerProgress ? 'determinate' : 'indeterminate'
					}
					value={progress}
				/>
				{showSpinnerProgress && (
					<Box
						top={0}
						left={0}
						bottom={0}
						right={0}
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Typography
							variant="caption"
							component="div"
							style={{ fontSize: '0.675rem' }}
						>{`${Math.round(progress)}%`}</Typography>
					</Box>
				)}
			</Box>
			{message}
		</>
	);
}

export default React.memo(ReadProgress);
