import LoginPage from '../pages/Login/Login';
import LandingPage from '../pages/Landing/LandingPage';
import JobsPage from '../pages/Jobs/JobsPage';
import PageNotFound from '../pages/PageNotFound';

const routes = [
	{
		path: '/login',
		component: LoginPage,
		isPrivate: false,
	},
	{
		path: '/jobs',
		component: JobsPage,
		isPrivate: true,
	},
	{
		path: '/',
		component: LandingPage,
		isPrivate: true,
	},
	{
		path: '/*',
		component: PageNotFound,
		isPrivate: false,
	},
];

export default routes;
