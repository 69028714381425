import React, { useState, useEffect } from 'react';
import {
	FormControl,
	CircularProgress,
	TextField,
	Tooltip,
	Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import asyncPool from 'tiny-async-pool';

export default function CtaPicker({ setCtas, hubIds }) {
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState([]);
	const [ctasIds, setCtaIds] = useState([]);

	const onChange = (_, value) => {
		setValues(value);
		if (value.length !== 0) setCtas(value);
		else setCtas(ctasIds);
	};

	async function getCtas(hubIds) {
		const resArray = await asyncPool(10, hubIds, (hubId) => {
			const res = axios.get(`/ctas/${hubId}/`);
			return res;
		});

		return resArray;
	}

	useEffect(() => {
		let active = true;

		// Reset Values
		clearSelected();
		setLoading(true);

		getCtas(hubIds)
			.then((res) => {
				if (active) {
					const ctas = res.map((r) => r.data.ctas);
					setOptions([].concat.apply([], ctas));
					setCtas([].concat.apply([], ctas));
					setCtaIds([].concat.apply([], ctas));
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				if (active) setLoading(false);
			});

		return () => {
			active = false;
		};
	}, [hubIds, setCtas]);

	const label = 'Select CTA(s)';
	const disabled = loading;
	const tooltipText = loading ? 'Loading ctas...' : '';

	const clearSelected = () => {
		setValues([]);
	};

	return (
		<FormControl size="small" variant="outlined">
			<Tooltip arrow placement="top" title={tooltipText}>
				<Autocomplete
					id="cta-picker"
					multiple
					style={{ width: '250px' }}
					size="small"
					disabled={disabled}
					loading={loading}
					options={options}
					groupBy={(option) => option.hub_id}
					getOptionLabel={(option) => option.name}
					value={values}
					onChange={onChange}
					limitTags={1}
					disableCloseOnSelect
					renderOption={(option, { selected }) => (
						<>
							<Checkbox color="primary" checked={selected} />
							{option.name}
						</>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={label}
							variant="outlined"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loading && (
											<CircularProgress
												color="primary"
												size={20}
											/>
										)}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
				/>
			</Tooltip>
		</FormControl>
	);
}
