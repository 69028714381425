import React, { useState, useEffect } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import { logoutUser, useAuthDispatch, useAuthState } from '../../context/auth';
import { useSocket } from '../../context/SocketProvider';
import ReadProgress from './ReadProgress';
import ss from '@sap_oss/node-socketio-stream';

export default function SocketDataExport({
	operatorData,
	handleSetFileData,
	jobStatus,
	handleSetJobStatus,
	buttonText = `Export ${operatorData.category}`,
	initializingText = `Reading Uberflip ${operatorData.category}`,
	downloadingText = `Downloading Data`,
	splitButton = false,
	runHybrid = [],
	disabled = false,
	hubIds = null,
	streamIds = null,
	ctaIdsName = null,
	flipbookId = null,
}) {
	const styles = useStyles();
	const socketEvent = `Export ${operatorData.category}`;
	const dispatch = useAuthDispatch();
	const socket = useSocket();
	const { hubs } = useAuthState();
	const [progress, setProgress] = useState(0);
	const [updatedHubIds, setUpdatedHubIds] = useState([]);

	useEffect(() => {
		if (hubIds && hubIds.length > 0) {
			setUpdatedHubIds(hubIds);
		} else {
			setUpdatedHubIds(
				hubs.map((hub) => {
					return hub.id;
				})
			);
		}
	}, [hubIds, hubs]);

	useEffect(() => {
		if (socket == null) return;
		socket.on(`${socketEvent}_progress`, (progress) => {
			setProgress(progress);
		});

		socket.on(`${socketEvent}_error`, (data) => {
			handleSetJobStatus({
				loading: false,
				state: 'read error',
				message: data.message,
				code: data.code,
			});
			if (data.code === 401) logoutUser(dispatch);
		});

		ss(socket).on(`${socketEvent}_stream`, function (stream, fileSize) {
			setProgress(0);
			const jsonArray = [];
			handleSetJobStatus({
				loading: true,
				state: null,
				code: null,
				message: downloadingText,
			});

			//Fired every chunk from stream
			stream.on('data', function (data) {
				//Collect json filedata
				jsonArray.push(...data);
				//Calculate download progreess
				const downloadProgress = Math.floor(
					Math.floor((jsonArray.length / fileSize) * 100.0)
				);
				setProgress(downloadProgress);
			});

			//Fired when all chunks are received
			stream.on('end', function () {
				handleSetFileData(jsonArray);

				handleSetJobStatus({
					loading: false,
					state: null,
					code: null,
					message: null,
				});
			});

			stream.on('error', function (error) {
				handleSetJobStatus({
					loading: false,
					state: 'read error',
					message: error,
					code: 409,
				});
			});
		});

		return () => {
			socket.off(`${socketEvent}_progress`);
			socket.off(`${socketEvent}_error`);
			ss(socket).removeAllListeners();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket, operatorData.category, dispatch]);

	const readData = () => {
		socket.emit(`${socketEvent}`, {
			uberflipHubs: hubs,
			hubIds: updatedHubIds,
			streamIds,
			ctaIdsName,
			flipbookId,
			runHybrid: runHybrid,
		});
		handleSetJobStatus({
			loading: true,
			state: null,
			code: null,
			message: initializingText,
		});
	};

	return (
		<>
			<Button
				className={splitButton ? styles.populated : styles.export}
				variant="contained"
				onClick={() => readData()}
				disabled={jobStatus.loading || disabled}
				startIcon={!jobStatus.loading && splitButton && <Description />}
				fullWidth={splitButton ? false : true}
				style={{ height: '42px' }}
			>
				{jobStatus.loading ? (
					<ReadProgress
						message={jobStatus.message}
						progress={progress}
					/>
				) : (
					<Box>{buttonText}</Box>
				)}
			</Button>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	populated: {
		boxShadow: 'none',
		borderTopRightRadius: '0',
		borderBottomRightRadius: '0',
		borderRight: '1px solid white !important',
	},
	export: {
		marginTop: '1rem',
	},
}));
