const userData = localStorage.getItem('currentUser')
	? JSON.parse(localStorage.getItem('currentUser'))
	: null;

export const initialState = {
	email: userData?.email,
	accountId: userData?.accountId,
	firstName: userData?.first_name,
	lastName: userData?.last_name,
	avatarURL: userData?.avatar_url,
	hubs: userData?.hubs,
	userId: userData?.id,
	userPermissions: userData?.userPermissions,
	userType: userData?.type,
	gitBranch: userData?.gitBranch,
	gitCommitHash: userData?.gitCommitHash,
	version: userData?.version,
	loggedIn: false,
	loginLoading: false,
	validatedLegacyCredentials: false,
	validatePrimaryUser: false,
	errorMessage: null,
	errorValidationPassphrase: false,
	errorValidationRESTCreds: false,
	companyName: userData?.company_name,
};

export const USER_ACTIONS = {
	REQUEST_LOGIN: 'REQUEST_LOGIN',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	LEGACY_LOGIN_SUCCESS: 'LEGACY_LOGIN_SUCCESS',
	LEGACY_LOGIN_ERROR: 'LEGACY_LOGIN_ERROR',
	LOGOUT: 'LOGOUT',
	REQUEST_STATUS: 'REQUEST_STATUS',
	RETURN_STATUS: 'RETURN_STATUS',
};

export const AuthReducer = (initialState, action) => {
	switch (action.type) {
		case USER_ACTIONS.REQUEST_LOGIN:
			return {
				...initialState,
				loginLoading: true,
				errorMessage: null,
				errorValidationPassphrase: false,
				errorValidationRESTCreds: false,
			};
		case USER_ACTIONS.LOGIN_SUCCESS:
			return {
				...initialState,
				accountId: action.payload.accountId,
				loggedIn: action.payload.authenticated,
				email: action.payload.email,
				firstName: action.payload.first_name,
				lastName: action.payload.last_name,
				avatarURL: action.payload.avatar_url,
				hubs: action.payload.hubs,
				userId: action.payload.userId,
				userPermissions: action.payload.userPermissions,
				userType: action.payload.userType,
				gitBranch: action.payload.gitBranch,
				gitCommitHash: action.payload.gitCommitHash,
				version: action.payload.version,
				validatePrimaryUser: action.payload.validatePrimaryUser,
				validatedLegacyCredentials:
					action.payload.validatedLegacyCredentials,
				companyName: action.payload.company_name,
				loginLoading: false,
				errorMessage: null,
			};

		case USER_ACTIONS.LOGIN_ERROR:
			return {
				...initialState,
				email: null,
				accountId: null,
				firstName: null,
				lastName: null,
				avatarURL: null,
				hubs: [],
				userId: null,
				userPermissions: null,
				userType: null,
				loggedIn: action.error.authenticated,
				loginLoading: false,
				errorMessage: action.error.message,
				errorValidationPassphrase: action.passphraseError,
				errorValidationRESTCreds: action.restCredsError,
			};
		case USER_ACTIONS.LOGOUT:
			return {
				...initialState,
				email: null,
				accountId: null,
				firstName: null,
				lastName: null,
				avatarURL: null,
				hubs: [],
				userId: null,
				userPermissions: null,
				userType: null,
				loggedIn: action.payload.authenticated,
				validatedLegacyCredentials: false,
				validatePrimaryUser: false,
				loginLoading: false,
				errorMessage: null,
			};
		case USER_ACTIONS.REQUEST_STATUS:
			return {
				...initialState,
				errorMessage: null,
				statusLoading: true,
			};
		case USER_ACTIONS.RETURN_STATUS:
			return {
				...initialState,
				validatedLegacyCredentials:
					action.payload.validatedLegacyCredentials,
				loggedIn: action.payload.authenticated,
				gitBranch: action.payload.gitBranch,
				gitCommitHash: action.payload.gitCommitHash,
				errorMessage: null,
				statusLoading: false,
			};
		case USER_ACTIONS.LEGACY_LOGIN_SUCCESS:
			return {
				...initialState,
				validatedLegacyCredentials:
					action.payload.validatedLegacyCredentials,
				errorMessage: null,
			};
		case USER_ACTIONS.LEGACY_LOGIN_ERROR:
			return {
				...initialState,
				validatedLegacyCredentials:
					action.payload.validatedLegacyCredentials,
				errorMessage: action.payload.error,
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
