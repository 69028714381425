import Axios from 'axios';
import { USER_ACTIONS } from './reducer';
Axios.defaults.withCredentials = true;

export async function loginUser(dispatch, payload) {
	const { passphrase, autoAuthToken, authCreds } = payload;

	try {
		dispatch({ type: USER_ACTIONS.REQUEST_LOGIN });

		const result = await Axios({
			url: '/auth/login',
			method: 'post',
			data: {
				passphrase,
				authCreds,
				autoAuthToken,
			},
		});

		if (result.data.authenticated) {
			const previousUser = localStorage.getItem('currentUser');

			const usersChanged = previousUser?.userId !== result.data.userId;
			const permissionsChanged =
				JSON.stringify(result.data.userPermissions) !==
				JSON.stringify(previousUser?.userPermissions);

			if (usersChanged || permissionsChanged) {
				clearSessionVariables();
			}

			dispatch({
				type: USER_ACTIONS.LOGIN_SUCCESS,
				payload: {
					...result.data,
					validatePrimaryUser:
						result.data.userType.toLowerCase() === 'primary',
				},
			});
			localStorage.setItem('currentUser', JSON.stringify(result.data));
			return result.data;
		}
	} catch (e) {
		dispatch({
			type: USER_ACTIONS.LOGIN_ERROR,
			error: e.response.data,
			passphraseError: e.response.data.message
				.toString()
				.includes('Passphrase'),
			restCredsError: e.response.data.message
				.toString()
				.includes('Account'),
		});
	}
}

export async function logoutUser(dispatch) {
	try {
		const result = await Axios({
			url: '/auth/logout',
			method: 'delete',
		});

		if (!result.data.authenticated) {
			dispatch({ type: USER_ACTIONS.LOGOUT, payload: result.data });
			localStorage.removeItem('currentUser');
			clearSessionVariables();
			await Axios({
				url: 'api/saml/clear-cookie',
				method: 'post',
			});
			return result.data;
		}
	} catch (e) {
		localStorage.removeItem('currentUser');
		clearSessionVariables();
		console.log(e);
	}
}

export async function validateUser(dispatch) {
	try {
		dispatch({ type: USER_ACTIONS.REQUEST_STATUS });

		const result = await Axios({
			url: '/api/saml/auth-status',
			method: 'get',
		});

		if (result.status === 200) {
			const { authenticated } = result.data;
			if (!authenticated) {
				localStorage.removeItem('currentUser');
				const samlRequest = await Axios({
					url: '/api/saml/saml-request',
					method: 'get',
				});

				const { loginUrl } = samlRequest.data;

				window.location.replace(loginUrl);
			} else {
				const userRequest = await Axios({
					url: '/api/saml/user',
					method: 'get',
				});
				const { data } = userRequest;
				localStorage.setItem('currentUser', JSON.stringify(data));
				dispatch({
					type: USER_ACTIONS.LOGIN_SUCCESS,
					payload: {
						...data,
						validatePrimaryUser:
							data.type.toLowerCase() === 'primary',
					},
				});
			}
		}

		if (result.data) {
			dispatch({
				type: USER_ACTIONS.RETURN_STATUS,
				payload: result.data,
			});
			return result.data;
		}
	} catch (e) {
		dispatch({
			type: USER_ACTIONS.RETURN_STATUS,
			payload: { authenticated: false },
		});
		localStorage.removeItem('currentUser');

		clearSessionVariables();
	}
}

export async function validateLegacyCreds(
	dispatch,
	legacyKey,
	legacySignature,
	hubs,
	setExpandedAccordionCallback
) {
	try {
		const result = await Axios({
			url: '/auth/legacy',
			method: 'post',
			data: {
				legacyKey,
				legacySignature,
				hubs,
			},
		});

		if (result.data) {
			dispatch({
				type: USER_ACTIONS.LEGACY_LOGIN_SUCCESS,
				payload: result.data,
			});
			return result.data;
		}
	} catch (e) {
		dispatch({
			type: USER_ACTIONS.LEGACY_LOGIN_ERROR,
			payload: {
				legacyAuthenticated: false,
				error: true,
			},
		});
		setExpandedAccordionCallback(true);
		sessionStorage.removeItem('bulksmasher-operator');
	}
}

function clearSessionVariables() {
	sessionStorage.removeItem('bulksmasher-operationJSON');
	sessionStorage.removeItem('bulksmasher-category');
	sessionStorage.removeItem('bulksmasher-operator');
}
