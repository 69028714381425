import React, { useState } from 'react';
import { AccordionActions, Button } from '@material-ui/core';
import { InsertDriveFile } from '@material-ui/icons';
import SplitTemplateButton from './SplitTemplateButton';
import ExportToXLSX from '../TemplateExporter/XLSX/ExportToXLSX';

export default function TemplateGeneratorDownload({
	operatorData,
	jobStatus,
	handleSetJobStatus,
	selectedParams,
}) {
	const [fileData, setFileData] = useState(null);

	const handleSetFileData = (data) => {
		setFileData(data);
	};

	const showPopulated =
		operatorData.operator.includes('Update') &&
		operatorData.category !== `Streams Options`;

	return (
		<>
			<AccordionActions>
				<p>Download</p>
				{showPopulated ? (
					<>
						<SplitTemplateButton
							operatorData={operatorData}
							selectedHeaders={selectedParams}
							jobStatus={jobStatus}
							handleSetFileData={handleSetFileData}
							handleSetJobStatus={handleSetJobStatus}
						/>
					</>
				) : (
					<Button
						variant={showPopulated ? 'outlined' : 'contained'}
						size="medium"
						startIcon={<InsertDriveFile />}
						onClick={() => {
							setFileData([]);
						}}
					>
						BLANK TEMPLATE
					</Button>
				)}
			</AccordionActions>

			{fileData && (
				<ExportToXLSX
					operatorData={operatorData}
					fileData={fileData}
					selectedHeaders={selectedParams}
					options={{
						restructureData: true,
					}}
					handleSetJobStatus={handleSetJobStatus}
				/>
			)}
		</>
	);
}
